import {useEffect} from "react";

export function ExternalLink(props){


    function routeToUrl(){
        window.open(props.url,'_blank');
    }

    return(
        <span onClick={routeToUrl} className={props.className?props.className:'externalLink'}>{props.label}</span>

    );
}