import logo from './logo.svg';
import * as React from "react";
import './App.css';
import "bootstrap-icons/font/bootstrap-icons.css"
import "react-image-gallery/styles/css/image-gallery.css";
import Header from "./components/Header";
import {Routes, Route, Link} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import About from "./pages/About";
import Thesis from "./pages/Thesis";
import Orbt from "./pages/Orbt";
import Timescape from "./pages/Timescape";
import Nia from "./pages/Nia";
import NotFound from "./pages/NotFound";
import Footer from "./components/Footer";
import LockdownPlaybook from "./pages/LockdownPlaybook";
import Overwhelmed from "./pages/Overwhelmed";
import Bserp from "./pages/Bserp";
import Hiatus from "./pages/Hiatus";
import {Container} from "react-bootstrap";
import Unwind from "./pages/Unwind";

function App() {
  return (
      <div className="App">
        <Header/>
        <div className="body-container min-vh-100">
          <Routes>
            <Route path="/index.html" element={<LandingPage />} ></Route>
              <Route path="/" element={<LandingPage />} ></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/thesis" element={<Thesis />}></Route>
              <Route path="/unwind" element={<Unwind />}></Route>
              <Route path="/orbt" element={<Orbt />}></Route>
              <Route path="/timescape" element={<Timescape />}></Route>
              <Route path="/nia" element={<Nia />}></Route>
              <Route path="/lockdownPlaybook" element={<LockdownPlaybook />}></Route>
              <Route path="/overwhelmed" element={<Overwhelmed />}></Route>
              <Route path="/bserp" element={<Bserp />}></Route>
              <Route path="/hiatus" element={<Hiatus />}></Route>
            <Route path="/*" element={<NotFound />}></Route>
          </Routes>
        </div>
        <Footer/>
      </div>
  );
}

export default App;
