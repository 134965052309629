import {Carousel} from "react-bootstrap";
import TIMESCAPE_HERO from "../assets/images/thesis/image-asset.jpeg";

export function ControlledCarousel(props){

    const items = props.items.map((item,index)=>
        <Carousel.Item key={index}>
            <img src={item.url} className="d-block w-100"/>

        <Carousel.Caption className="carousel-caption-below-image mb-4">
            <p>{item.caption}</p>
        </Carousel.Caption>

        </Carousel.Item>
    );

    return(
    <Carousel variant="dark" interval={props.interval} className={props.className?props.className:''}>
        {items}
    </Carousel>
    );
}