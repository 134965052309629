import {Component} from "react";
import {Carousel} from "react-bootstrap";

class CarouselComponent extends Component{
    constructor(props) {
        super();
        this.state={selectedIndex:0};
        this.handleSelect= this.handleSelect.bind(this);
    }
componentDidMount() {
        if(this.props.activeIndex!=null){
            this.handleSelect(this.props.activeIndex);
        }
}

    handleSelect(i) {
        this.setState({
            selectedIndex: i
        });
    }

    render(){
        const images = this.props.images;
       // this.handleSelect(this.props.activeIndex);
        const carouselItems = images.map((image,index)=>
            <Carousel.Item key={index}>
                <img
                    className="d-block w-100"
                    src={image.original}
                    alt="First slide"
                />
            </Carousel.Item>
        );
        return(
            <Carousel activeIndex={this.state.selectedIndex} onSelect={this.handleSelect}>
                {carouselItems}
            </Carousel>
        );
    }
}
export default CarouselComponent