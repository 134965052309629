import {Component, useEffect, useState} from "react";
import {Container, Nav, Navbar, NavbarBrand} from "react-bootstrap";
import {Link, NavLink, useLocation} from "react-router-dom";

export default function Header(){

    const location = useLocation();
    //const [currentPage,setCurrentPage] = useState(location.pathname);
    const [lightNavTextColor,setLightNavTextColor] = useState(false);
    const [whiteBackground,setWhiteBackground] = useState(false);
    const [visible,setVisible] = useState(true);
    const [prevScrollPosition,setPrevScrollPosition] = useState(0);

    let handleScroll = debounce(()=>{
        let currentScrollPos = window.scrollY;
        // Add the following condition if navbar is to be displayed when doing small increments of downward scroll
        //||(prevScrollPosition<currentScrollPos&&(currentScrollPos-prevScrollPosition)<50)
        if(currentScrollPos<100){
            setWhiteBackground(false);
        }else{
            setWhiteBackground((prevScrollPosition>currentScrollPos&&(prevScrollPosition-currentScrollPos)>100));
        }
        setVisible((prevScrollPosition>currentScrollPos&&(prevScrollPosition-currentScrollPos)>100)||currentScrollPos<10);


        setPrevScrollPosition(currentScrollPos);
        //console.log(currentScrollPos);
        if(location.pathname.includes('thesis')||location.pathname.includes('bserp')){
            if(currentScrollPos<400){
                setLightNavTextColor(true);
            }else{
                setLightNavTextColor(false);
            }
        }

    },200);

    function handleNavTextColor(){
        //console.log(location.pathname);
        if(location.pathname.includes('thesis')||location.pathname.includes('bserp')){
            setLightNavTextColor(true);
        }else if(location.pathname.includes('orbt')){
            setLightNavTextColor(false);
        }else if(location.pathname.includes('lockdownPlaybook')){
            setLightNavTextColor(false);
        }
        else{
            setLightNavTextColor(false);
        }
    }

    useEffect(()=>{
        handleNavTextColor();
       // window.scrollTo({top:0, left:0,behavior:'auto'});
        window.scrollTo(0,0);
    },[location]);

    useEffect(()=>{
        window.addEventListener('scroll',handleScroll);
        return () => window.removeEventListener('scroll',handleScroll);
    },[prevScrollPosition,visible,handleScroll])

   function debounce(func, wait, immediate) {
        let timeout;
        return function() {
            let context = this, args = arguments;
            let later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            let callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };
    // let activeStyle = {
    //     textdecoration: "underline"
    // };
    let activeClassName = "underline"
        return(
            <Navbar expand="lg" className="fixed-top px-lg-3 navbarStyles" className={`fixed-top px-lg-3 navbarStyles ${lightNavTextColor ? 'lightNavFontColor' : 'darkNavFontColor'} ${whiteBackground ? 'white-nav-background' : ''}`} style={{top:visible?'0':'-60px'}}>
                <Container fluid>
                    <Navbar.Brand><Link to="/" className="brand">Karan Bansal</Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            {/*<NavLink to="/" className={({isActive})=>isActive?activeClassName:undefined}>WORK</NavLink>*/}
                            {/*<NavLink to="/thesis" className={({isActive})=>isActive?activeClassName:undefined}>THESIS</NavLink>*/}
                            <Nav.Link as={NavLink} to="/" className={({isActive})=>isActive?activeClassName:undefined}>WORK</Nav.Link>
                            <Nav.Link as={NavLink} to="/thesis" className={({isActive})=>isActive?activeClassName:undefined}>THESIS</Nav.Link>
                            <Nav.Link as={NavLink} to="/about" className={({isActive})=>isActive?activeClassName:undefined}>ABOUT</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
}
