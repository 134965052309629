export const lp_hero_1_svg = (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 800 800">
    <title>1.1.1</title>
    <polygon className="cls-1"
             points="54 136.5 64 310 199.5 295 193 118 54 136.5"/>
    <polygon className="cls-1"
             points="71.5 152.5 76.5 290.5 188.5 280.5 179.5 139.5 71.5 152.5"/>
    <line className="cls-1" x1="71.5" y1="152.5" x2="54" y2="136.5"/>
    <line className="cls-1" x1="179.5" y1="139.5" x2="193" y2="118"/>
    <line className="cls-1" x1="188.5" y1="280.5" x2="199.5" y2="295"/>
    <line className="cls-1" x1="76.5" y1="290.5" x2="64" y2="310"/>
    <polygon className="cls-1"
             points="125 350.33 133 477.67 303.33 437.33 299.33 319 125 350.33"/>
    <polygon className="cls-1"
             points="138.33 364.67 144.67 458.67 289.67 425.33 284 336.33 138.33 364.67"/>
    <polyline className="cls-1"
              points="407.43 206.61 410.33 67.67 226.67 80 232.67 303.67 406 274.67 406 274.67"/>
    <polyline className="cls-1"
              points="389.07 187.19 387.31 88 247 97.67 251.33 280.5 390.33 258 390.33 258"/>
    <line className="cls-1" x1="226.67" y1="80" x2="247" y2="97.67"/>
    <line className="cls-1" x1="410.33" y1="67.67" x2="387.31" y2="88"/>
    <line className="cls-1" x1="251.33" y1="280.5" x2="232.67" y2="303.67"/>
    <line className="cls-1" x1="390.3" y1="256.32" x2="406" y2="274.67"/>
    <polyline className="cls-1" points="588.5 104 777.5 104 771 350 620.5 350"/>
    <polyline className="cls-1" points="449.5 234.5 452.5 104 510.75 104"/>
    <line className="cls-1" x1="635.16" y1="414.11" x2="800" y2="368.03"/>
    <line className="cls-1" x1="459.72" y1="463.15" x2="473.17" y2="459.39"/>
    <polyline className="cls-1"
              points="384.04 607.97 125 688.33 0 591.67 379.72 485.52"/>
    <line className="cls-1" x1="804" y1="477.67" x2="650.74" y2="525.22"/>
    <polyline className="cls-1" points="125 688.33 125 725 0 625"/>
    <polyline className="cls-1" points="379.97 641.14 125 725 125 800"/>
    <line className="cls-1" x1="800" y1="503" x2="650.74" y2="552.09"/>
    <line className="cls-1" y1="714.33" x2="104.33" y2="800"/>
    <path className="cls-1"
          d="M629.72,643.34c99-32.8,170.28-57.67,170.28-57.67"/>
    <path className="cls-1" d="M146.33,800c.77.9,95.53-29.9,214.33-69"/>
    <path className="cls-1"
          d="M357.67,800C343.67,791.7,375,663.67,375,663.67c15.33-58,8-107.34,8-107.34A28,28,0,0,0,402,576s74.86,3.16,76.23,2.74S486,577.5,486,572.5c0,0,25.5,178.5,23,197.5,0,0-1,28-2.5,30"/>
    <circle className="cls-1" cx="301.37" cy="708.28" r="12.67"/>
    <circle className="cls-1" cx="736.76" cy="560.97" r="12.67"/>
    <path className="cls-1"
          d="M746.74,553.17s-11.11-14.17-23.49-15-18.62,9.12-13.75,20.25c0,0,11,9.49,20.41,13.24"/>
    <path className="cls-1"
          d="M312.68,702.58S301,681.5,287.5,682.25s-15.17,10.15-15.17,10.15-6.9,11.9,19.47,24.17"/>
    <ellipse className="cls-1" cx="218.76" cy="585.67" rx="75.67" ry="20"
             transform="translate(-142.17 75.18) rotate(-14.78)"/>
    <path className="cls-1" d="M168,598.5s87.5-6,105.25-29"/>
    <path className="cls-1"
          d="M400,575.29s-26-36,11.5-49.54c0,0,26-9.71,41.5,24.77"/>
    <path className="cls-1"
          d="M426.38,577s-8.75-36.84,26.62-27.11c0,0,19.75,3.74,13.38,27.11"/>
    <path className="cls-1"
          d="M465.73,559.57s6-9.65,13.52-3.24,9,16.42-3.87,21.42"/>
    <line className="cls-1" x1="385" y1="561.66" x2="391.5" y2="546.43"/>
    <line className="cls-1" x1="400" y1="531.95" x2="409.5" y2="514.33"/>
    <line className="cls-1" x1="479.25" y1="556.33" x2="475.35" y2="538.33"/>
    <path className="cls-1"
          d="M613.5,800S580,763,637,625s-59-373-59-373,4.25-27.12,0-32.5"/>
    <path className="cls-1"
          d="M642,800s-48.67-29.35,19-95.69,95.67-73,95.67-73-11.67-8.66-4-17.66,8.33-7.34,8.33-7.34,10,6.34,10.33,9,7.34,0,7.34,0,4,6,1.66,9S782,627,786.67,628s12.66,13,13.33,19.67"/>
    <path className="cls-1"
          d="M710,719.67s-24.67-25,12.33-43.67c0,0,22.52-.33,27.09,8s11.91,16.67,1.91,30.33S727.33,736.67,710,719.67Z"/>
    <path className="cls-1"
          d="M712.53,722s-34.53,8.68-21.86,39.34c0,0,13.27,20.67,38.47,7.34,0,0,24.71-14.34,7.62-41.34"/>
    <path className="cls-1" d="M739.86,733.06S769,694,800,735"/>
    <path className="cls-1"
          d="M734.27,764.6s4.4,17.07,28.4,21.73S800,775.46,800,775.46"/>
    <path className="cls-1"
          d="M656,800s-41-22.2-14-52.43c0,0,24.63-26.24,50.32-12.57"/>
    <path className="cls-1" d="M679.33,731.06s-14.23-33.83,22.72-27.94"/>
    <path className="cls-1"
          d="M760,725.63s4.93,7,7.13,7.7-12.8,3-10.8,7.34,15.34,0,15.34,0S769.33,748.33,774,751s7.33-9.33,7.33-9.33,10.67,7.47,13.67,5.9S787.67,735,787.67,735s-7-9.33-11.82-7.67C775.85,727.33,761.67,720.26,760,725.63Z"/>
    <path className="cls-1"
          d="M712.53,731.06S699.89,739.33,702,741s13.38-3.5,13.38-3.5S721,752.11,723,751.39s2.67-13.89,2.67-13.89,11.84,4.5,11.09,2.17S714.06,725.78,712.53,731.06Z"/>
    <path className="cls-1"
          d="M730.67,682.33s-10.34-1.33-9.67,3-4.67,5.67-3.33,9.34,13,0,13,0,7.39,12.66,9.19,11.66,0-15.33,0-15.33,5.47,3.46,9.56,1.84S734,679,730.67,682.33Z"/>
    <path className="cls-1"
          d="M681,742s-26.33-5.56-35.67,9.39,17.23-3.82,17.23-3.82,3.11,18.76,5.44,14.76,3-14.76,3-14.76,8,10.76,12,10.43S683.37,742.4,681,742Z"/>
    <path className="cls-1"
          d="M800,666.67s-28.47-30-30.07-32.67,0-8.67,0-8.67"/>
    <path className="cls-1"
          d="M775.85,631s-10.74,15.33-6.63,15,19.45-13,18.78-7.5-17.7,22.17-9.85,19.83,13.18-11,16.18-6.83-5.66,14.17-2.66,15.17,8.33-7,8.33-7"/>
    <path className="cls-1"
          d="M534.5,274.67s19.5,1-6.5,17.41S399.5,372,382.5,400s-28.28,88.78,39.61,123.14c0,0,69.39,15.64,80.89,26.75,0,0,39,7.39,15.5-26.75,0,0-16-22.64-30-28.64s-33.5-39-30.5-45,53-43,110-49.5"/>
    <path className="cls-1" d="M473.17,436.8s-2.17,38.19,0,44"/>
    <path className="cls-1"
          d="M453,340.92s.57-12.59-21.38-37.25S324.39,188.22,335.67,139.5c0,0,4-22.83,22.66,0s24.35,75.82,150.84,126"/>
    <path className="cls-1"
          d="M547.75,269.75S481.35,302,509.17,187c0,0-16.92-7.5-12.17-31.25s21.25-20,21.25-20S479,88.67,553.5,77.83c0,0,44.75-3.83,38.25,54.67,0,0,49.25-19.5,50.75,20,0,0,9.5,42-41.25,39.75,0,0,32.75,3.25,23.5,21.75,0,0-2.5,19.5-46.75,5.5,0,0-34.25,13.7-27-43.65,0,0-15.4,22.4-41.83,11.15"/>
    <circle className="cls-1" cx="515.75" cy="223.92" r="2.58"/>
    <line className="cls-1" x1="597.59" y1="296.63" x2="772.44" y2="295.35"/>
    <line className="cls-1" x1="607.92" y1="323.28" x2="771.79" y2="320.03"/>
    <path className="cls-1"
          d="M635.5,295.35s4.25-36.35,28-37.6c0,0,5.5-1.25,2.5-5.75,0,0-13-15.25-1.5-20.75s15,2.25,15,2.25,7.5,7.75,1.5,16c0,0-8,6-1,9.5,0,0,16-1.06,20.75,36.35"/>
    <path className="cls-1"
          d="M637.51,322.69a27.06,27.06,0,0,0,6.74,7.06v-7.06L647.5,350"/>
    <path className="cls-1" d="M661,350s5.25-50.75,12.75,0"/>
    <path className="cls-1"
          d="M699,321.47s-2.75,7.28-4.25,8.78c0,0-.5,17.5,0,19.75"/>
    <path className="cls-1"
          d="M679.17,296c6.33-1.3,10.69-13.7,10.69-13.7H649.33s2.34,11.27,11,14.3"/>
    <path className="cls-1"
          d="M670,265.42s-13.08,2.83-2.42,7.58-4.18,4.67-4.18,4.67"/>
    <path className="cls-1"
          d="M680.92,264.83s-12.42,4.5-1.25,8.34-5,5.41-5,5.41"/>
    <path className="cls-1"
          d="M660.64,242.91s-11.47-.53-11.31-6.91,8.67-6.72,8.67-6.72-15-7.53-3.87-16.4,23.75,6.87,23.75,6.87,18.27-1.28,16.25,8c-1.5,6.88-5.25,9.13-5.25,9.13s9.37,4.12,7,8.37-15.69,4.93-15.69,4.93"/>
    <circle className="cls-1" cx="668.61" cy="242.14" r="0.49"/>
    <circle className="cls-1" cx="675.94" cy="241.9" r="0.33"/>
    <polyline className="cls-1"
              points="649.33 104 649.33 119.33 658 119.33 713.33 119.33 713.33 104"/>
    <line className="cls-1" x1="658" y1="119.33" x2="641.43" y2="144.73"/>
    <line className="cls-1" x1="599.77" y1="224.29" x2="581.33" y2="253.67"/>
    <line className="cls-1" x1="699" y1="119.33" x2="724.33" y2="173"/>
    <path className="cls-1"
          d="M774.68,210.83s-.35-17.33-38.68-15c0,0-4.67-3.66,0-7.16,0,0-3.67-12-21.17-9.5s-12,15.66-1.5,17.83c0,0,46.38-.23,25.77,99.63"/>
    <path className="cls-1"
          d="M774.22,228s-9.39,2.66-9.22,8.82l1.67,28.59s-7,8.5-10,6.29-4.34-39.54-4.34-39.54"/>
    <path className="cls-1"
          d="M707.72,181.31s15.53-15.43,34.28.19c0,0,4.06,13,13.91,15.2"/>
    <polyline className="cls-1" points="709.95 195.85 713 225.5 742.26 229.28"/>
    <line className="cls-1" x1="717.38" y1="197.61" x2="721.38" y2="226.58"/>
    <line className="cls-1" x1="726.8" y1="202.11" x2="731.25" y2="228.01"/>
    <polyline className="cls-1"
              points="282 150.25 262 152 260.25 118 292 118 292 126.75"/>
    <path className="cls-1"
          d="M282.5,198.5c-15.5-84.5,26.75-77,26.75-77s23.86,2.71,27.56,14.23"/>
    <path className="cls-1"
          d="M303.33,138.39S290.5,145.5,297.25,161c0,0,13.33,13.75-3.08,27,0,0-28.92,13-27.17,66.25l88.25-11.5s-3.5-46.92-21.5-57.08c0,0-22-6.92-15.25-24.67,0,0,10.5-8.5,8.5-15.75C327,145.25,324.67,129.64,303.33,138.39Z"/>
    <path className="cls-1"
          d="M287.75,212.75s-2.5,19.78,21.5,22.39,15.3,11.61,15.3,11.61"/>
    <path className="cls-1" d="M337.75,196.25s-25.89,34-39.32,35.89"/>
    <path className="cls-1" d="M298.43,142.92s-10.41-7-7.67,47.15"/>
    <line className="cls-1" x1="277.25" y1="118" x2="277.25" y2="150.67"/>
    <polyline className="cls-1"
              points="331.1 128.75 328 105.25 351.88 104.61 374.5 104 375.75 168.15"/>
    <line className="cls-1" x1="351.88" y1="104.61" x2="354.99" y2="135.73"/>
    <circle className="cls-1" cx="270.13" cy="129.88" r="1.13"/>
    <circle className="cls-1" cx="270.13" cy="140.63" r="1.13"/>
    <circle className="cls-1" cx="342.81" cy="115.31" r="1.31"/>
    <circle className="cls-1" cx="364.28" cy="113.53" r="1.78"/>
    <circle className="cls-1" cx="366.15" cy="130.62" r="1.87"/>
    <path className="cls-1"
          d="M143.74,444.85s18.93-36.52,22.93-37.68,0-8,0-8-11.5-10.67-2.17-15.67,14.67-1.17,14.67-1.17,11,5.5,3,17.67"/>
    <path className="cls-1"
          d="M156.42,456s6.91-49.14,32.58-55.14c0,0,10.33,3,6.33-6.66s-4.66-14.5,2.34-16.84,13.83,6.17,13.83,6.17-.5,9.17-3.33,11.67,2.33,6.66,2.33,6.66,22.11,3.82,19.39,37.25"/>
    <polyline className="cls-1"
              points="193.27 380.25 192.33 363 203.33 368.03 207.67 358.17 209.5 369.21 216.17 366.17 211.44 384.33"/>
    <path className="cls-1"
          d="M185.08,402.14v10.2a4.57,4.57,0,0,0,4.66,4.57l19.89-.38a4.56,4.56,0,0,0,4.48-4.62L214,403"/>
    <line className="cls-1" x1="178.67" y1="426.33" x2="177.14" y2="451.2"/>
    <line className="cls-1" x1="214.17" y1="423.17" x2="212.8" y2="443"/>
    <path className="cls-1"
          d="M219.38,406.28S233,400,232.83,397.5c0,0-.33-4.67-1.66-5.67s-11.34-8.33-6.67-16.16,14,0,14,0,7.67,4.33,6.33,12.83c0,0-.16,5.17,8,4.83s32.15-1.2,34.74,32.48"/>
    <path className="cls-1"
          d="M230.12,372.19s-1.79-12.86,12.88-11.36c0,0,8.33,3,2.17,8.5,0,0,8.83-.78,10,2.86s-6,8.74-6,8.74,10.16-.26,8.83,4.07-10.37,7.5-10.37,7.5"/>
    <polyline className="cls-1" points="230.7 400 247.33 406.83 260.2 393.42"/>
    <line className="cls-1" x1="247.33" y1="406.83" x2="251.73" y2="434.05"/>
    <circle className="cls-1" cx="261.17" cy="408.01" r="4"/>
    <circle className="cls-1" cx="264.21" cy="420.91" r="4"/>
    <path className="cls-1"
          d="M105.67,287.9S101.5,252.5,123,242.5a5,5,0,0,0,0-6.25s-12-13-1.25-16.5,13.5,3,13.5,3,2,8.25,0,11c0,0-6,4.75.89,8s30.69,19.23,20.28,41.61"/>
    <path className="cls-1" d="M126.75,248.25s-9,2.5,9,13"/>
    <path className="cls-1"
          d="M146.25,260s-1.75,5.75-25,1.25c0,0-12.75,1.25,0,7.75,0,0,23.5-.25,26.5,5.25"/>
    <path className="cls-1" d="M111.75,278.75s14.29-9.82,18.38-9.46"/>
    <path className="cls-1"
          d="M76.5,260s9-37,12-40.25c0,0,.75-4.25-1-5.75s-5.5-12.5,6.25-12.75c0,0,8.5-.25,8.75,6,0,0-.5,4.5-3.75,6.75,0,0-3.5,2.75,2,6.25,0,0,17.12,10,18.06,24.74"/>
    <path className="cls-1"
          d="M91.49,201.46s2-13.21,13.86-6a9.34,9.34,0,0,0,7.9,1.89s-5.06,9.36-7.9,9.61,8.4-.5,6.4,4.25-4.51,4.75-9.38,1.5"/>
    <path className="cls-1"
          d="M121.75,219.75s11.5-36-28.75-51c0,0,1-11.25,10.75-7,0,0,10.88.5,17.19,18.25,0,0,8.56,7.5,14.81,4,0,0-4-15,6.25-15s9.25,9.5,9.25,9.5-1,8.25-3.5,8.25c0,0-.95,2.25,3.53,2.75s25.18-6.09,33.45,31.95"/>
    <path className="cls-1"
          d="M144,246.71s-2.72-15,5.78-18.46c0,0,10.25-9.25,0-11.5,0,0-8.25-10.75,2.75-15.5,0,0,10.75-3.75,11.75,10.25,0,0-4.25,9.25,5.25,11,0,0,15.21,1.5,15.23,16"/>
    <line className="cls-1" x1="169.25" y1="204.18" x2="174.35" y2="223.75"/>
    <path className="cls-1"
          d="M136.25,172l-3.43-12.55a1.52,1.52,0,0,1,2.28-1.69c2.4,1.53,5.65,3.17,8.46,3s6.32-1.64,8.84-2.94a1.53,1.53,0,0,1,2.14,1.86l-3.63,10.41a1.52,1.52,0,0,0,1.32,2c3.07.25,8.65,1,8.65,2.86,0,2.63-9.75,4.36-9.75,4.36"/>
    <path className="cls-1" d="M736.25,320.73s-7.25,21.77-5,29.27"/>
    <path className="cls-1" d="M715.33,773.15s-1.66,24.22-15,26.87"/>
</svg>
);

export const lp_hero_2_svg =(
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 800 800">
        <defs>
            <style>.cls-1&#123;fill:none;&#125;.cls-1,.cls-2&#123;stroke:#231f20;stroke-miterlimit:10;stroke-width:5px;&#125;.cls-2&#123;fill:#231f20;&#125;</style>
        </defs>
        <title>3.2.4</title>
        <path className="cls-1"
              d="M27.61,93.83l15,11.6a2.38,2.38,0,0,1,.27,3.53l-31,24.28a2.39,2.39,0,0,0,.25,3.51L25,147.5a5.39,5.39,0,0,0,3.25,1.5,5.58,5.58,0,0,0,4.25-1.56l24.13-29.18A2.39,2.39,0,0,1,59.8,118l14,10.5a2.39,2.39,0,0,0,3.76-1.39L91.07,66.29a2.39,2.39,0,0,0-3.28-2.7L28.11,89.76A2.38,2.38,0,0,0,27.61,93.83Z"/>
        <path className="cls-1"
              d="M34.42,189.41a1.66,1.66,0,0,0-1.7,1.63h0L30.8,271.37a1.67,1.67,0,0,0,1.62,1.71l80,1.92a1.67,1.67,0,0,0,1.7-1.59l3.52-80.34a1.67,1.67,0,0,0-1.62-1.74Z"/>
        <polygon className="cls-1"
                 points="43.92 211.62 42.67 254.87 87.17 254.87 87.17 239.37 106.92 247.37 106.92 214.62 86.67 226.12 86.67 211.62 43.92 211.62"/>
        <path className="cls-1"
              d="M35.42,334.5v69.1a7.49,7.49,0,0,0,7.78,7.48l72-2.76a7.49,7.49,0,0,0,7.2-7.48V330.91a7.49,7.49,0,0,0-7.85-7.48l-72,3.59A7.49,7.49,0,0,0,35.42,334.5Z"/>
        <polygon className="cls-1"
                 points="55.09 352.71 55.09 378.71 74.8 378.71 94.76 398.37 94.76 332.37 70.42 353.71 55.09 352.71"/>
        <path className="cls-1" d="M268.5,375s45.89,35.4,39.6,80.05"/>
        <path className="cls-1"
              d="M257.48,454.05c-4-12.86-7.35-23.92-9.26-30.29a23.41,23.41,0,0,0-6.39-10.33c-8.14-7.65-24.67-21.74-31.33-16.93,0,0-29.5,31-21,72.5,0,0,2.07,7.33,5.78,18.32"/>
        <path className="cls-1" d="M258.1,456.05c-.21-.67-.41-1.34-.62-2"/>
        <path className="cls-1"
              d="M162.65,566.68l7.06,6.36a5,5,0,0,1,.75,6.58L65,729.5,155,568A5,5,0,0,1,162.65,566.68Z"/>
        <path className="cls-1"
              d="M186.74,613.58,145,749,205,624.26a6.66,6.66,0,0,0-1.29-7.6l-5.83-5.83A6.67,6.67,0,0,0,186.74,613.58Z"/>
        <path className="cls-1"
              d="M237.06,642.54,220.5,749l34.76-101.41a5.26,5.26,0,0,0-3.39-6.71l-8-2.54A5.25,5.25,0,0,0,237.06,642.54Z"/>
        <path className="cls-1"
              d="M295.26,651.77,309,756l6.18-104.59a5.11,5.11,0,0,0-5.1-5.41h-9.76A5.11,5.11,0,0,0,295.26,651.77Z"/>
        <path className="cls-1"
              d="M228,368.83s-8,7.67,7.25,14.67c0,0,16.75,15,44,12"/>
        <path className="cls-1" d="M278,395.92s12-1.16,1.25-11"/>
        <path className="cls-1" d="M354.33-.48V509.4"/>
        <path className="cls-1" d="M409.67,170.25V800.48"/>
        <line className="cls-1" x1="409.67" y1="-0.48" x2="409.67" y2="124"/>
        <path className="cls-1"
              d="M197.8,300.8s-11.53-23.08,5.35-27.62,24.92,6.52,24.92,6.52L240,293c-1-6.09-1.48-15.13,2.62-24.06,8.17-17.79,28-20.9,48.92-33,12.69-7.33,29.53-20.14,45.4-44,0,0,8-22.07-20.15-25.47"/>
        <path className="cls-1"
              d="M244.88,115.74,253.67,94a3.84,3.84,0,0,1-2.12-1s-1.43-1.27-1.44-4.32c0-3.42,4.91-12.5,13-23.71a27.12,27.12,0,0,1,10.43-1.93A24.24,24.24,0,0,1,286.7,67c2.48,1.64,5.65,3.74,5.39,5.74s-4,4.39-20.16,5.78c6.62,1.91,11.64,4.6,11.45,6.78-.21,2.35-6.48,4.33-15.41,4.66,1.51.65,8.2,3.58,7.78,5.83-.08.39-.52,1.58-6.61,2.54a55.56,55.56,0,0,1-17.18,0"/>
        <path className="cls-1"
              d="M355.18,170.88c-12.44,5.73-18.24,21.1-18.24,21.1s9.27-8.2,17.9-7.87"/>
        <circle className="cls-2" cx="320.73" cy="282.95" r="4.25"/>
        <line className="cls-1" x1="315.81" y1="293.85" x2="320.04" y2="303.58"/>
        <line className="cls-1" x1="309.17" y1="293.96" x2="313.2" y2="304.04"/>
        <line className="cls-1" x1="301.07" y1="292.4" x2="305.57" y2="303.33"/>
        <path className="cls-1" d="M220.3,169s20.78-10,51.62-8.85"/>
        <path className="cls-1"
              d="M410.87,121.22c4.5-2.78,20.52-11.69,28.63-2.72,0,0-.25-39.25,39.25-51S560.5,70,560.5,70a20.13,20.13,0,0,1,9.78,6.75c5.19,6.82,5.25,17,.22,26,2.1-1,22.22-9.71,40.5,1.25,14.22,8.52,18,23.35,19.5,29.5s6,24-5.25,39.5c-11.66,16.06-31,17.14-33.75,17.25a39.79,39.79,0,0,1-11.22,11.91c-13.84,9.55-29.38,6.83-33,6.09,0,0-35.75-3.75-27-51.75,0,0-14.75,38.25-47,36,0,0-39.25-1-35.58-29.5"/>
        <path className="cls-1"
              d="M518.5,313.25l-3.1-13.78a3.12,3.12,0,0,1,2.59-3.76l9.58-1.36a3.17,3.17,0,0,0,1.26-.48l18.74-12.25a3.1,3.1,0,0,1,4.79,2.8L550.77,310a3.11,3.11,0,0,1-4.24,2.69l-13.74-5.42a3.14,3.14,0,0,0-2.53.11l-10.77,5.39a3.09,3.09,0,0,0-1.44,1.5l-11,24.38a3.1,3.1,0,0,1-5.41.44L488.85,319.9a3.1,3.1,0,0,1,.68-4.17l23.52-18.28a3.06,3.06,0,0,0,1.07-3.34C509.91,281.34,493.33,281,493.33,281c-4.45,1.82-24.14,9.28-45.83.5-25.59-10.35-33.58-35.05-34.95-39.3C404,215.7,414,184.71,437.67,163c0,0-12.38,12.56-26,9.53"/>
        <line className="cls-1" x1="531.5" y1="306.75" x2="528.25" y2="294.25"/>
        <path className="cls-1"
              d="M451.67,800.48C438.33,782,469.67,573,469.67,573l12.69-89.53"/>
        <path className="cls-1"
              d="M512.67,326.18s5,21.49,4.66,30.15c-.18,4.73-1.55,26.55-2.76,45.42"/>
        <polyline className="cls-1"
                  points="499 800.48 507.94 558.13 510.35 501.25"/>
        <polyline className="cls-1"
                  points="567 800.48 559.05 541.63 559.05 525.44"/>
        <path className="cls-1"
              d="M556,386.67A323.35,323.35,0,0,1,567,274s21.5-29.49,52-4a796.67,796.67,0,0,1,98,151.67c74.8,151.1,84.89,293.27,83.48,378.81"/>
        <path className="cls-1"
              d="M710.67,385.33l16.17-10a10.19,10.19,0,0,0,4.83-8.66V339.52a10.19,10.19,0,0,1,10.19-10.19h33.55a10.19,10.19,0,0,1,10.17,9.58L792,445.53a10.18,10.18,0,0,1-10.17,10.8h-34a10.19,10.19,0,0,1-10.17-9.53l-3.19-49.87a10.19,10.19,0,0,0-8.73-9.43Z"/>
        <path className="cls-1"
              d="M760.41,379.46l-8.68-8.34c-5.49-5-7.85-11.13-6-15.11a7.9,7.9,0,0,1,3.7-3.64A8,8,0,0,1,757,353a8.42,8.42,0,0,1,3.46,4.86c.16-.3,2.94-5.13,7.71-5.15a7.74,7.74,0,0,1,3.69,1,6.55,6.55,0,0,1,2.33,3.08c.48,1.44.79,4.35-6.83,14.33C765.81,373.21,763.47,376.12,760.41,379.46Z"/>
        <path className="cls-1"
              d="M765.7,436.55l-11.85-9.05c-7.47-5.39-11.23-12.61-9.45-17.78a9.73,9.73,0,0,1,4.06-5c4.23-2.55,8.91-.48,9.39-.26a11.15,11.15,0,0,1,5.32,5.37c.12-.32,2.68-6.57,8.4-7.29a9.2,9.2,0,0,1,4.68.69,8.13,8.13,0,0,1,3.31,3.47c.79,1.7,1.58,5.25-6.41,18.61C771.48,428.1,769,432,765.7,436.55Z"/>
        <path className="cls-1"
              d="M642.8,300.5l57.87-3.85a18.36,18.36,0,0,1,18.06,10.84l5,11.26,15.39-28.45a18.34,18.34,0,0,1,13.41-9.41l10.39-1.57a18.36,18.36,0,0,0,15.56-19.75l-7.89-90.18a18.37,18.37,0,0,0-20-16.68L641.33,162.9a18.37,18.37,0,0,0-16.63,19.35c2,33.41,3.85,68.7,5.8,102.12"/>
        <path className="cls-1"
              d="M637,258.5s21.75-31.25,27.5-51.75S676,217.5,676,217.5s2.5,41.25-20.75,55.75"/>
        <path className="cls-1"
              d="M698,276.75s-17.75-46.25-17.75-60.25,1-31,21.37-6,1.88,5,41.13,41.23"/>
        <path className="cls-1"
              d="M659.25,183.75c-.75,0,13.5-13,13.5-13l3.33,21.75Z"/>
        <polygon className="cls-1"
                 points="686.75 165 682.25 189.75 701.62 173.5 686.75 165"/>
        <polygon className="cls-1"
                 points="697.75 185.75 718.5 180.25 714.25 197.72 697.75 185.75"/>
        <circle className="cls-1" cx="533.31" cy="332.81" r="5.06"/>
        <circle className="cls-1" cx="533.31" cy="362.98" r="5.06"/>
        <circle className="cls-1" cx="533.31" cy="390.55" r="5.06"/>
        <circle className="cls-1" cx="533.49" cy="574.98" r="5.06"/>
        <circle className="cls-1" cx="533.49" cy="614.65" r="5.06"/>
        <circle className="cls-2" cx="447.25" cy="242.24" r="4.25"/>
        <path className="cls-1" d="M465.63,256c.5.38,9.75,9.25,9.75,9.25"/>
        <line className="cls-1" x1="474.5" y1="251.73" x2="485.13" y2="261.25"/>
        <line className="cls-1" x1="485.13" y1="248.5" x2="495.5" y2="256.49"/>
        <path className="cls-1"
              d="M575.68,204.87s7.07,15.6-21.18,37.37c0,0-7,6.24,0,17.38"/>
        <path className="cls-1"
              d="M513.05,297.45,527.3,279a7.87,7.87,0,0,1,2.3-2l39-22.28A7.78,7.78,0,0,1,578,256l5.57,5.64"/>
        <circle className="cls-1" cx="448.46" cy="39.88" r="7.09"/>
        <line className="cls-1" x1="472.25" y1="24" x2="472.25" y2="55.75"/>
        <path className="cls-1" d="M472.25,39.88s5-17.8,20.5-8.21"/>
        <path className="cls-1"
              d="M494.2,39.16a17.94,17.94,0,0,0,21.05-3.41s3.25-10.59-14.5-6.92c0,0-13.75,7.67-1.75,16.92a15.71,15.71,0,0,0,7.16,2.61,16.12,16.12,0,0,0,10.34-2.61"/>
        <path className="cls-1"
              d="M538.5,28.83S522,26.23,523,38.24s17.75,7,17.75,7"/>
        <path className="cls-1"
              d="M558,31.67s-9.25-1.29-8.5,5.77c0,0-1.5,9.62,6.5,8.84S564.5,39,564.5,39,565.75,32.33,558,31.67Z"/>
        <line className="cls-1" x1="577.75" y1="28.06" x2="576" y2="53.25"/>
        <path className="cls-1" d="M577,39s6-12.37,17.76-7.28"/>
        <path className="cls-1"
              d="M613,38.24s-13.45-4.65-15.2,4.81S614,51.25,614,51.25l-2.5-31"/>
        <line className="cls-1" x1="630.25" y1="35.78" x2="631.5" y2="54.43"/>
        <line className="cls-1" x1="644" y1="31.67" x2="644" y2="53.25"/>
        <path className="cls-1" d="M645.25,38.24s6.19-13.76,14,0l1,15"/>
        <path className="cls-1"
              d="M679.25,45.1c-13.5,5.86-13-2.64-13-2.64-2.75-10,14-8.11,14-8.11s4.5,32.9,0,39.9-20.42,5.25-23.71-3.75"/>
        <polygon className="cls-1"
                 points="629.38 28.15 629.38 30 631.13 30 631.13 27.62 629.38 28.15"/>
        <path className="cls-1"
              d="M381,310.53c-6.16-4.85-13.35-5.15-16.69-1.91-2.77,2.67-2.58,7.38-2.47,8.37a14.85,14.85,0,0,0,3.44,8.3c3.47,3.86,8.42,4.34,12.19,5.08,5.24,1,12.69,3.44,21.5,9.75.44-10.5.92-18.8,1.25-24.13.69-11.2,1.17-16.52-2.12-18.95-2.95-2.19-7.52-1-10.54.14A12.48,12.48,0,0,0,381,310.53Z"/>
        <path className="cls-1"
              d="M384.6,380.92c-1.12-5.22-4.79-8.56-8.07-8.36-2.77.16-4.84,2.76-5.27,3.41a10.61,10.61,0,0,0-2,6.13c.08,3.73,2.55,6.31,4.26,8.48A39.12,39.12,0,0,1,380.64,406c5.17-5.52,9.32-9.81,12-12.56,5.63-5.78,8.39-8.45,7.74-11.32-.59-2.58-3.63-4.07-5.82-4.87a10.36,10.36,0,0,0-5.77.55A10.25,10.25,0,0,0,384.6,380.92Z"/>
        <line className="cls-1" x1="409.67" y1="122" x2="409.67" y2="176.25"/>
        <path className="cls-1"
              d="M229.35,362.35l-.19,1.49c-.72,4.71-2.33,4.47-2.33,4.47C197.3,356.84,171,476,171,476c-3.47,20-40.08,58-49.7,67.7-.59.6-1.17,1.22-1.73,1.85,0,0-1.44,1.64-2.72,3.44C116.85,549,10,698-.48,800.48"/>
        <path className="cls-1"
              d="M229.16,363.84l2.22-17c1.49-7.23,1.39-17.17-6-26.29,0,0-43.18-22.88-54.28-57.62a77.39,77.39,0,0,1-.06-41.22C181.23,185.49,215.47,171,220.3,169c0,0-46.09-32.86-5.9-66.84,0,0,39.67-23.39,64.37,22.65,0,0,8.85,20.05-6.85,35.34,0,0,39-4.16,64,29.72A126,126,0,0,1,350,226.5c2.3,10.86,8.05,38-4.5,62.5-5,9.75-12.17,17.63-16.67,21a95.59,95.59,0,0,1-13.5,8.17h0a111,111,0,0,1-24.9,8.87c-17.55,4.27-23.89,2.07-28.93,7.46-3.06,3.27-5.87,9.68-2.8,23.63,0,0,6.81,14.15,11,17.88l-.81.29"/>
        <path className="cls-1"
              d="M413.34,363.69a51.33,51.33,0,0,1-3.67-19c0-51.34,31.33-18.42,37.58-8.67s45.39,25.21,45.39,25.21"/>
        <path className="cls-1" d="M489.75,394.75l6.75-64.33"/>
        <path className="cls-1"
              d="M671.5,434.5c-71.5,122-129.77,86.21-129.77,86.21-64-40.28-127.52-73.4-131.58-122.38-.45-5.51-2.68-32.42,8.47-37.52,7.85-3.59,18.33,5.45,27.35,11.74,31.45,21.92,76.1,38.38,92.41,28.29,0,0,25.62-7.84,51.87-76.59"/>
        <path className="cls-1"
              d="M354.12,413.62c0-.33,0-.66,0-1,.17-22.3-8.49-15.63-14.49-9.3S313,417.21,303.32,420.44"/>
        <path className="cls-1" d="M309.17,448.37a91,91,0,0,0,18.08-8.87"/>
        <path className="cls-1"
              d="M328.4,438.47c-20.57,18.57-61.16,47.85-71.34,14.24"/>
        <path className="cls-1"
              d="M194,483.36S209,547,257,522s97.33-46,97.33-85-9.58-17-14.08-10.5c-1.06,1.53-5.5,6.24-11.85,12"/>
        <path className="cls-1" d="M354.33,508.83V800.48"/>
        <path className="cls-1" d="M303.32,497.65s2.85,73.08,51,153.21"/>
        <path className="cls-1" d="M228,367.55s33,27.47,46.2,12.34"/>
    </svg>
);