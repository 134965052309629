import {Component} from "react";
import {Col, Container, Figure, Row} from "react-bootstrap";
import ORBT_HERO from '../assets/images/orbt/orbt_hero.png';
import {Link} from "react-router-dom";
import OVERWHELMED_HERO from "../assets/images/overwhelmed/Hardcover-Hero.png";
import TIMESCAPE_6 from '../assets/images/timescape/timescape-instruction.png';
import BSERP_HERO from "../assets/images/bserp/BSERP-Hero.001.png";
import NIA_HERO from "../assets/images/nia/nia-laptop.png";
import LP_HERO from "../assets/images/lockdown-playbook/lp-grid-img.png";
import HIATUS_2 from "../assets/images/hiatus/hiatus-logo-screen.png";
import UNWIND_BACKGROUND from "../assets/images/unwind/Unwind-product-2.png";

class LandingPage extends Component{

    render() {
        return(
            <div>
                <Container fluid className="g-0">
                    <Row className="landing-page-intro">
                        <Col md={10} className="ms-auto me-auto">
                            <p>Hello 👋, I’m an NYC-based  <span className="fw-bold">Strategic Product Designer</span> with a background in Engineering and Software Development. I believe that design should have a <span className="fw-bold">humanizing&nbsp;influence</span> and approach every problem with that&nbsp;intent.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10} className="ms-auto me-auto">
                            <Row>
                                <Col>
                                    <Link to="/unwind" className="clickable">
                                        <div className="ar-16x9-figure">
                                            <img src={UNWIND_BACKGROUND}/>
                                        </div>
                                        <p className="grid-img-title"> <b>Unwind</b> — Digital design to help manage attention overload</p>
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Link to="/hiatus" className="clickable">
                                        <div className="ar-3x2-figure">
                                            <img src={HIATUS_2}/>
                                        </div>
                                        <p className="grid-img-title"><b>Hiatus</b> — Digital design to subvert productivity in 24x7 workplaces</p>
                                    </Link>
                                </Col>
                                <Col md={6}>
                                    <Link to="/overwhelmed" className="clickable">
                                    <div className="ar-3x2-figure">
                                        <img src={OVERWHELMED_HERO} className="grid-img"/>
                                    </div>
                                    <p className="grid-img-title"><b>Overwhelmed</b> — Book design to articulate my MFA&nbsp;thesis</p>
                                    </Link>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Link to="/orbt" className="clickable">
                                    <div className="ar-16x9-figure">
                                        <img src={ORBT_HERO}/>
                                    </div>
                                    <p className="grid-img-title"> <b>ORBT</b> — Product & Brand design to minimize wasteful behavior towards ballpoint pens</p>
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Link to="/bserp" className="clickable">
                                        <div className="ar-3x2-figure">
                                            <img src={BSERP_HERO} className="grid-img"/>
                                        </div>
                                        <p className="grid-img-title"><b>BSERP</b> — Digital design to simplify Enterprise Resource Planning</p>
                                    </Link>
                                </Col>
                                <Col md={6}>
                                    <Link to="/timescape" className="clickable">
                                        <div className="ar-3x2-figure">
                                            <img src={TIMESCAPE_6}/>
                                        </div>
                                        <p className="grid-img-title"> <b>Timescape</b> — Speculative design for Bio-Hacking perceived&nbsp;time</p>
                                    </Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Link to="/lockdownPlaybook" className="clickable">
                                        <div className="ar-3x2-figure">
                                            <img src={LP_HERO} className="grid-img"/>
                                        </div>
                                        <p className="grid-img-title"><b>The Lockdown Playbook</b> — UI design to spark physical behaviors</p>
                                    </Link>
                                </Col>
                                <Col md={6}>
                                    <Link to="/nia" className="clickable">
                                        <div className="ar-3x2-figure">
                                            <img src={NIA_HERO}/>
                                        </div>
                                        <p className="grid-img-title"><b>NIA AI-Ops</b> — Platform design for DevOps automation </p>
                                    </Link>
                                </Col>
                            </Row>




                    {/*<Row>*/}
                    {/*    <Col md={6}>*/}
                    {/*    <div className="ar-71">*/}
                    {/*        <img src={ORBT_HERO}/>*/}
                    {/*    </div>*/}
                    {/*        <p className="subtitle">ORBT - Product Design for circular economy</p>*/}
                    {/*    </Col>*/}
                    {/*    <Col md={6}>*/}
                    {/*    <Row>*/}
                    {/*        <Col md={6}>*/}
                    {/*            <div className="ar-62">*/}
                    {/*                <img src={OVERWHELMED_HERO}/>*/}
                    {/*            </div>*/}
                    {/*            <div className="ar-18">*/}
                    {/*                <p className="subtitle">Overwhelmed - Book Design to articulate my MFA Thesis</p>*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*        <Col md={6}>*/}
                    {/*            <div className="ar-62">*/}
                    {/*                <img src={TIMESCAPE_6} />*/}
                    {/*            </div>*/}
                    {/*            <p className="subtitle">Timescape - Speculative Design for Bio-Hacking perceived time</p>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*        <Row >*/}
                    {/*            <Col md={6}>*/}
                    {/*                <div className="ar-62">*/}
                    {/*                    <img src={BSERP_HERO} />*/}
                    {/*                </div>*/}
                    {/*                <p className="subtitle">BSERP - Product Design of an employee first ERP</p>*/}
                    {/*            </Col>*/}
                    {/*            <Col md={6}>*/}
                    {/*                <div className="ar-62">*/}
                    {/*                    <img src={ORBT_HERO} className="grid-img"/>*/}
                    {/*                </div>*/}
                    {/*                <p className="subtitle">ORBT - Product Design for circular economy</p>*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                        </Col>
                    </Row>
            </Container>
            </div>
        );
    }
}
export default LandingPage