import {Component} from "react";

function colorOnHover(classElement,index) {
    let r = 10*index;
    let g = 50;
    let b = 100;
    if(r>255){
        r = r/10;
    }
    if(g>255){
        g = g/10;
    }
    if(b>255){
        b = b/10;
    }
//rgb(".concat(10*index).concat(",").concat(5*index).concat(",100)");
    var randomColor = '#'+Math.floor(Math.random()*16777215).toString(16);
//.concat(r).concat(",").concat(g).concat(",").concat(b).concat(")")
//classElement.setAttribute("style","fill:rgb(".concat(randomColor).concat(")"));
    classElement.setAttribute("style","fill:".concat(randomColor).concat(";"));

}

class ColoringBook extends Component{

    componentDidMount() {
        let classes = document.querySelectorAll("[class^=st]");

        let svg = document.querySelector("#Layer_1");
        for(let i=0;i<classes.length;i++){
            (function(i){
                classes[i].onmouseover = function(){
                    colorOnHover(this,i);
                }
            })(i);
        }
    }

    render(){
        return(
            <div>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                     x="0px" y="0px"
                     viewBox="0 0 800 800">
                                                    <g id="Layer_2_1_">
	<path className="st0" d="M129.9,174.4c0-84.9,68.8-153.8,153.8-153.8s153.8,68.8,153.8,153.8s-68.8,153.8-153.8,153.8
		c-34.3,0-72.9-17.9-98.5-36.9"/>
                                                        <path className="st0"
                                                              d="M402.9,422c-27.4-6.6-51.9-28.9-58.7-58.2c-3.9-16.7,0.6-37.5,7-52.1"/>
                                                        <path className="st0"
                                                              d="M444,419.3c-2.5,0.9-5,1.6-7.7,2.2c-5.4,1.2-12.1,1.9-17.4,2"/>
                                                        <path className="st0"
                                                              d="M401.9,271.6c41.4-9.5,82.6,16.3,92.2,57.7c7.5,32.6-8,65.9-34.7,82.9"/>
                                                        <path className="st0" d="M0,404c2-7.8,43.9-100.8,73.9-102.8s43.9-36.8,43.9-36.8s-22-40.7-14-52.4s-4-34.9,31.9-46.5
		s71.7,30.5,74.1,73.2c1.3,22.9-22.2,56.8-40.2,54.8s-31.9-24.9-39.9-9.5c-8.3,15.9-18,28.9-8,38.6s69.9,42.7,77.9,81.4
		s45.9,60.1,69.9,56.2s61.9-46.5,67.9-38.8s-12,19.4,0,21.3c4.8,0.8,14.1-2.8,23.5-7.4c6.5-3.1,12.6,4.8,7.8,10.2
		c-17,19.4-42.8,44.3-71.2,55.3c-25,9.7-62.9,30.1-88.1,34.7s-47.9,0-77.9-16.3c-29.3-15.9-37.6-32-41.6-41.7"/>
                                                        <path className="st0" d="M321.4,695.7c0,0-35.6-156-75.3-172.2"/>
                                                        <path className="st0"
                                                              d="M321.4,800V596.2c0-19.4,15.6-35.3,35-35.5l443.6-6.5V800H321.4z"/>
                                                        <path className="st1" d="M519.1,559.7H370.4c-6.1,0-11.1-5-11.1-11.1l-6.3-54.7c0-6.1,5-11.1,11.1-11.1h166.3c6.1,0,11.1,5,11.1,11.1
		l-11.3,54.7C530.2,554.7,525.2,559.7,519.1,559.7z"/>
                                                        <g>
		<path className="st2" d="M406.5,462.1h7.9c2.7,0,4.9-2.2,4.9-4.9v-46c0-2.7-2.2-4.9-4.9-4.9h-3v-2.7c3.4-0.5,5.9-3.4,5.9-6.8
			c0-3.5-5.5-9.9-6.2-10.6c-0.4-0.4-1.1-0.4-1.4,0c-0.6,0.7-6.2,7.1-6.2,10.6s2.6,6.4,5.9,6.8v2.7h-3c-2.7,0-4.9,2.2-4.9,4.9v46
			C401.6,459.9,403.8,462.1,406.5,462.1z M405.5,396.8c0-1.9,2.8-5.9,5-8.5c2.2,2.6,5,6.6,5,8.5c0,2.4-1.7,4.4-4,4.9v-3.5
			c0-0.5-0.4-1-1-1s-1,0.4-1,1v3.5C407.3,401.2,405.5,399.2,405.5,396.8z M417.5,439v5.8h-13.9V439H417.5z M403.6,437.1v-5.8h13.9
			v5.8H403.6z M417.5,429.4h-13.9v-5.8h13.9V429.4z M403.6,446.7h13.9v5.8h-13.9V446.7z M417.5,421.7h-13.9v-5.8h13.9V421.7z
			 M414.5,460.2h-7.9c-1.6,0-3-1.3-3-3v-2.8h13.9v2.8C417.5,458.9,416.1,460.2,414.5,460.2z M406.5,408.2h7.9c1.6,0,3,1.3,3,3v2.8
			h-13.9v-2.8C403.6,409.5,404.9,408.2,406.5,408.2z"/>
	</g>
                                                        <g>
		<path className="st2" d="M447.9,456.5h7.9c2.7,0,4.9-2.2,4.9-4.9v-46c0-2.7-2.2-4.9-4.9-4.9h-3V398c3.4-0.5,5.9-3.4,5.9-6.8
			c0-3.5-5.5-9.9-6.2-10.6c-0.4-0.4-1.1-0.4-1.4,0c-0.6,0.7-6.2,7.1-6.2,10.6s2.6,6.4,5.9,6.8v2.7h-3c-2.7,0-4.9,2.2-4.9,4.9v46
			C443,454.3,445.2,456.5,447.9,456.5z M446.9,391.1c0-1.9,2.8-5.9,5-8.5c2.2,2.6,5,6.6,5,8.5c0,2.4-1.7,4.4-4,4.9v-3.5
			c0-0.5-0.4-1-1-1s-1,0.4-1,1v3.5C448.6,395.6,446.9,393.6,446.9,391.1z M458.9,433.4v5.8H445v-5.8H458.9z M444.9,431.5v-5.8h13.9
			v5.8H444.9z M458.9,423.8H445V418h13.9V423.8z M444.9,441.1h13.9v5.8h-13.9V441.1z M458.9,416.1H445v-5.8h13.9V416.1z
			 M455.9,454.6H448c-1.6,0-3-1.3-3-3v-2.8h13.9v2.8C458.9,453.3,457.5,454.6,455.9,454.6z M447.9,402.6h7.9c1.6,0,3,1.3,3,3v2.8
			h-13.9v-2.8C444.9,403.9,446.3,402.6,447.9,402.6z"/>
	</g>
                                                        <g>
		<path className="st2" d="M481.1,471.5h7.9c2.7,0,4.9-2.2,4.9-4.9v-46c0-2.7-2.2-4.9-4.9-4.9h-3V413c3.4-0.5,5.9-3.4,5.9-6.8
			c0-3.5-5.5-9.9-6.2-10.6c-0.4-0.4-1.1-0.4-1.4,0c-0.6,0.7-6.2,7.1-6.2,10.6s2.6,6.4,5.9,6.8v2.7h-3c-2.7,0-4.9,2.2-4.9,4.9v46
			C476.2,469.4,478.4,471.5,481.1,471.5z M480.1,406.2c0-1.9,2.8-5.9,5-8.5c2.2,2.6,5,6.6,5,8.5c0,2.4-1.7,4.4-4,4.9v-3.5
			c0-0.5-0.4-1-1-1s-1,0.4-1,1v3.5C481.8,410.6,480.1,408.6,480.1,406.2z M492,448.4v5.8h-13.9v-5.8H492z M478.1,446.5v-5.8H492v5.8
			H478.1z M492,438.8h-13.9V433H492V438.8z M478.1,456.1H492v5.8h-13.9V456.1z M492,431.1h-13.9v-5.8H492V431.1z M489,469.6h-7.9
			c-1.6,0-3-1.3-3-3v-2.8H492v2.8C492,468.3,490.7,469.6,489,469.6z M481.1,417.6h7.9c1.6,0,3,1.3,3,3v2.8h-13.9v-2.8
			C478.1,419,479.5,417.6,481.1,417.6z"/>
	</g>
                                                        <path className="st1" d="M508.1,480.5l-120.5-2.2c-4.6,0-8.3-3.7-8.3-8.3l-3.9-17.8c0-4.6,3.7-8.3,8.3-8.3h127.1c4.6,0,8.3,3.7,8.3,8.3
		l-2.8,20C516.4,476.8,512.7,480.5,508.1,480.5z"/>
                                                        <path className="st3" d="M517.5,470c0,0-12.8-16.2-29.5-10.1s-10.8,10.6-23.3,10.1s-16.1-21.9-22.1-15.7c-6,6.1-4.7,16.2-14.4,15.7
		s-9.5-10.7-16.1-8.4c-6.7,2.3-9.1,8.9-13.9,8.4s-21.8-8-21.8-8"/>
                                                        <ellipse className="st4" cx="508.18" cy="495.14" rx="4" ry="4"/>
                                                        <circle className="st5" cx="417.4" cy="504" r="8.8"/>
                                                        <line className="st3" x1="358.4" y1="540.6" x2="531.6"
                                                              y2="540.6"/>
                                                        <path className="st3" d="M355.3,514c6.7-8.7,22.1-20,37.1-11.8c22.3,12.2,14.4,21.1,31.2,20.1c16.8-1,21.6-43.6,29.6-31.4
		s6.3,32.4,19.2,31.4s12.7-21.4,21.6-16.8s12.1,17.8,18.5,16.8c6.4-1,26.1-14,26.1-14"/>
                                                        <polygon className="st0"
                                                                 points="701.2,217.6 576.3,522 606.9,531.9 729.3,225.6 	"/>
                                                        <polygon className="st0"
                                                                 points="704.9,293.5 628.4,490.2 642.1,498.2 717.6,300.1 	"/>
                                                        <path className="st0" d="M685.2,385.2c0,0,14.8,57.8,25.8,60.5c11,2.6-44.1,86.2-44.1,86.2h117.5V554h-156l55.6-99l-19-16.8
		L685.2,385.2z"/>
                                                        <path className="st6" d="M178.4,191.5c0,0,50.4-24-0.2-40.3c0,0,7.1-33.4-8.3-35.4s-21,5.8-21,5.8s-15-18.2-29.1,4.7
		c0,0-24.3-24.7-37.8-8.3s14.3,41.4,14.3,41.4S57,156,83.8,188.8c0,0-28.7,26.2-7.1,33c21.6,6.9,17.4-4.1,17.4-4.1
		s-1.4,23.9,20.4,16.1c0,0,7,4.7,12.6,1.6c5.6-3.1,12.5-27.7,12.5-27.7s13,8.2,23.3,5.2C173.1,209.8,178.4,191.5,178.4,191.5z"/>
                                                        <circle className="st7" cx="190.3" cy="251" r="1.8"/>
                                                        <path className="st0" d="M71.4,746.8l179.8-46.3c10.7-2.8,21.8,2.7,26.2,12.9l0,0c7.6,17.7-2.9,38-21.7,42.1l-30.2,6.6L73.1,795.3
		c-11.9,2.6-23.7-4.8-26.5-16.5l0,0c-2.4-9.7,1.3-20,7.8-24.1l5.5-3.2C61.5,750.5,69.5,747.3,71.4,746.8z"/>
                                                        <polyline className="st0"
                                                                  points="289.4,800 292.1,765.8 225.5,762.1 	"/>
                                                        <polyline className="st0"
                                                                  points="0,690.5 142.6,728.5 154.1,777.7 176.6,772.8 199.1,767.8 189.4,716.4 162.9,723.2 173.4,772.3 	"/>
                                                        <path className="st0" d="M172,718.5v-42.4c0-3.6,2.7-6.6,6.2-7c2.4-0.3,5.7-0.3,10.2,0c17.2,1.1,32.9,9.9,28.9,15s-13.2,26.2-28,32.3
		c-11.6,4.8-11.2,0.9-10.5-1c0.3-0.8,0.4-1.6,0.4-2.4c0.1-6.1,0.8-28.8,5.8-28.5c2.7,0.2,6.8,0.5,10.3,0.8c4.4,0.4,7.4,4.7,6.1,9
		c-2.1,7.3-7.1,17.3-19,21.7"/>
                                                        <path className="st0" d="M169.6,718.1l-33.9-47.8c-2.9-4-7.5-5.9-10.7-4.3c-2.2,1.1-4.9,2.9-8.4,5.9c-13.2,11.2-19,30.2-11.6,33.6
		c7.4,3.5,31.6,21.9,48.6,20.2c13.4-1.3,9.9-5.5,7.7-7.2c-0.9-0.7-1.6-1.5-2.3-2.5c-5-6.9-23.6-32-27.5-28.8
		c-2.1,1.8-5.1,4.5-7.8,6.8c-3.3,3-2.2,9.6,2.2,13.7c7.5,7,19.6,15.4,32.8,13.5"/>
                                                        <polyline className="st0"
                                                                  points="240.7,765.8 235.8,800 185.2,800 185.2,773.4 212.9,764.8 208.6,799.3 	"/>
                                                        <line className="st0" x1="686.7" y1="252.8" x2="375.4" y2="0"/>
                                                        <line className="st0" x1="357.5" y1="532.5" x2="272.8"
                                                              y2="554"/>
                                                        <line className="st0" x1="597" y1="471.5" x2="538.6"
                                                              y2="486.4"/>
                                                        <path className="st0" d="M403.6,423.6"/>
                                                        <circle className="st1" cx="566.9" cy="144.2" r="106.1"/>
                                                        <path className="st1" d="M353,56.2c0,0,23.8,10.5,34.3,30.3"/>
                                                        <line className="st0" x1="393.2" y1="97.2" x2="396.6"
                                                              y2="103.4"/>
                                                        <path className="st1" d="M481.8,335.7c0,0,5.1,21.2-2.5,37.9"/>
                                                        <line className="st0" x1="475.2" y1="382.9" x2="472.8"
                                                              y2="388.2"/>
                                                        <path className="st1" d="M652.8,178.6c0,0-9.3,24.3-28.5,35.8"/>
                                                        <line className="st0" x1="614" y1="220.9" x2="607.9"
                                                              y2="224.6"/>
                                                        <circle className="st1" cx="659.6" cy="705.7" r="70.3"/>
                                                        <path className="st1" d="M716.5,728.4c0,0-6.2,16.1-18.8,23.7"/>
                                                        <line className="st0" x1="690.8" y1="756.4" x2="686.8"
                                                              y2="758.9"/>
                                                        <circle className="st1" cx="686.7" cy="74.8" r="39.3"/>
                                                        <path className="st8" d="M718.5,85.7c0,0-3.4,9-10.5,13.3"/>
                                                        <line className="st3" x1="704.2" y1="101.3" x2="701.9"
                                                              y2="102.7"/>
                                                        <title>Artboard 23</title>
                                                        <path className="st0" d="M262.6,144.1c-0.1,2.4-2.2,4.1-4.5,4s-4.1-2.2-4-4.5s2.2-4.1,4.5-4S262.7,141.7,262.6,144.1L262.6,144.1z
		 M313.9,155c-2.2-1.3-4.5-2.5-6.9-3.6l-0.9-0.4c-1.3-0.6-2.6-1.4-3.7-2.4c-0.7-0.6-1.1-1.4-1-2.3c0.2-0.9,1-1.6,1.9-1.9
		c0.7-0.2,1.4-0.3,2.1-0.3c3.1,0,6.2,0.8,9,2.3c0.8,0.6,1.9,0.8,2.9,0.5c0.9-0.4,1.5-1.2,1.8-2.2c0.5-1.2,0.9-2.4,1.3-3.6l0.5-1.4
		c0.7-1.6-0.1-3.5-1.7-4.1l0,0c-2-1.1-4.2-1.8-6.4-2.3c-1.6-0.3-1.6-0.3-1.5-2c0.3-4.4-0.1-5.3-4.6-5.6l-2.3-0.1
		c-2.6-0.2-3.6,0.8-3.7,3.3c0,0.6-0.1,1.1-0.1,1.6v0.9c-0.1,1.5-0.1,1.5-1.6,1.9c-6.8,2-10.6,6.4-11.4,12.9
		c-0.7,5.6,1.6,10.1,7,13.7c2,1.3,4,2.4,6.1,3.3c0.9,0.4,1.7,0.8,2.7,1.3s2,1.2,2.8,1.9c1.4,1,1.6,2.9,0.6,4.3
		c-0.4,0.6-1,0.9-1.7,1.1c-1.6,0.6-3.3,0.7-5,0.3c-2.9-0.5-5.6-1.5-8.1-2.9c-0.6-0.4-1.3-0.7-2-0.8c-1.8-0.1-2.4,1.6-2.7,2.4
		c-0.5,1.4-1,2.9-1.6,4.5c-0.9,2.7-0.4,3.8,2,5.2l0,0c2.5,1.3,5.3,2.3,8.1,2.8l1,0.2c0,0.3,0,0.9-0.1,1.1l-0.2,3
		c-0.2,1.7,1,3.3,2.7,3.5c0.1,0,0.2,0,0.4,0l1.9,0.1l2.4,0.1c1.7,0.2,3.2-1.1,3.3-2.7c0-0.1,0-0.2,0-0.4c0-0.4,0-0.8,0.1-1.2v-0.3
		c0-0.9,0.1-1.8,0.2-2.7s0-0.8,0.9-1c3.4-0.7,6.4-2.4,8.7-4.9c3-3.3,4.5-7.7,3.9-12.1C320.5,161.6,317.9,157.5,313.9,155z
		 M384.4,165.3c-0.3,4.4-4,7.7-8.4,7.5l-2.1-0.1c-2.7,11.2-8.6,21.4-17,29.4l-11,29.6c-2,5.3-7.2,8.8-12.9,8.4l-9.5-0.6
		c-5.7-0.4-10.5-4.5-11.8-10l-1.6-7.2c-7.3,0.5-14.7,0.1-21.9-1.3l-2.5,6.9c-1.9,5.4-7.2,8.9-12.9,8.6l-9.6-0.6
		c-5.7-0.3-10.5-4.3-11.8-9.9l-5.5-22.3c-10.8-5.6-20.6-12.8-29.3-21.3c-3.9-4-6-9.5-5.7-15.1l1.1-18.6c0.5-8.8,8.1-15.5,16.9-15
		l3.4,0.2c3.2-2.9,6.1-6.1,8.6-9.6l0.2-0.2c-0.7-2.7-2.1-5.2-4-7.3c-3-3.5-3.8-8.4-1.8-12.7c1.9-4.3,6.2-7.2,10.9-7.2
		c7.6-0.3,15.2,1.5,21.9,5.2c12-5.5,25.2-8,38.4-7.2c37.1,2.2,67.1,29.3,68.8,61.9l1.6,0.1C381.4,157.2,384.7,161,384.4,165.3z"/>
                                                        <path className="st0" d="M638.6,174.4l-49.2-0.6c0,0,10.3,20.6,0,22.8c-10.3,2.1-20.5,1.2-21.6-5c-1.1-6.1-0.9-24.8-0.9-36.1
		c0-11.3,9.4-29.1,9.4-29.1"/>
                                                        <path className="st1" d="M502,122.1c0,0,11.4,5.5,7.8,9.3"/>
                                                        <path className="st1" d="M594.4,83.4c0,0,32.2,25.9,34.1,39.7s6.7,22.1,10.1,25c3.5,2.8,13.7,4.3,14.2-2.3s-14.2-64.5-28.5-79.6
		c-17.5-18.6-23.8-18.7-48.1,5.2l-72.1,47l-4.6,8.1c0,0-11.9,6,8.6,6.9c7.3,0.3,22.8-0.6,39.9-2.1c31.1-2.8,73.9-10.1,78.4-13.5"/>
                                                        <path className="st1" d="M502,123.4c0,0,11.6,6.9,8.5,9.9"/>
                                                        <circle className="st1" cx="558.1" cy="106" r="1.2"/>
                                                        <circle className="st1" cx="524.1" cy="175.5" r="1.1"/>
                                                        <circle className="st1" cx="554.5" cy="227.6" r="4.9"/>
                                                        <circle className="st1" cx="532.4" cy="70" r="1.3"/>
                                                        <circle className="st6" cx="376.2" cy="233" r="3.4"/>
                                                        <circle className="st1" cx="284.3" cy="273.8" r="4.2"/>
                                                        <circle className="st6" cx="288.4" cy="57.4" r="4.7"/>
</g>
                    <polygon className="st1"
                             points="110.5,141.4 146.3,125.1 124,84 104.4,105.1 72.9,71.9 79.4,120 57.5,114.4 83.5,156.4 "/>
</svg>
            </div>
        );
    }
}
export default ColoringBook