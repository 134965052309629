import {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import THESIS_HERO from '../assets/images/thesis/thesis_hero.jpeg';
import TIMESCAPE_HERO from '../assets/images/thesis/image-asset.jpeg';
import TIMESCAPE_GIF from '../assets/images/thesis/image-asset.gif';
import UNWIND_HERO from '../assets/images/thesis/image-asset.png';
import PHASER_HERO from '../assets/images/thesis/image-asset(1).png';
import PHASER_PAPER from '../assets/images/thesis/image-asset(2).png';
import PHASER_DESK from '../assets/images/thesis/image-asset(3).png';
import HIATUS_HERO from '../assets/images/thesis/image-asset(4).png';
import HIATUS_1 from '../assets/images/thesis/image-asset(5).png';
import SILLY_HERO from '../assets/images/thesis/image-asset(6).png';
import ImageGallery from 'react-image-gallery';
import THESIS_GAL_1 from '../assets/images/thesis/5.7.21_PoDThesis_135.jpg';
import THESIS_GAL_1_T from '../assets/images/thesis/5.7.21_PoDThesis_135(1).jpg';
import THESIS_GAL_2 from '../assets/images/thesis/5.7.21_PoDThesis_136.jpg';
import THESIS_GAL_2_T from '../assets/images/thesis/5.7.21_PoDThesis_136(1).jpg';
import THESIS_GAL_3 from '../assets/images/thesis/5.7.21_PoDThesis_137.jpg';
import THESIS_GAL_3_T from '../assets/images/thesis/5.7.21_PoDThesis_137.jpg';
import THESIS_GAL_4 from '../assets/images/thesis/5.7.21_PoDThesis_141.jpg';
import THESIS_GAL_4_T from '../assets/images/thesis/5.7.21_PoDThesis_141(1).jpg';
import THESIS_GAL_5 from '../assets/images/thesis/5.7.21_PoDThesis_143.jpg';
import THESIS_GAL_5_T from '../assets/images/thesis/5.7.21_PoDThesis_143(1).jpg';
import THESIS_GAL_6 from '../assets/images/thesis/5.7.21_PoDThesis_146.jpg';
import THESIS_GAL_6_T from '../assets/images/thesis/5.7.21_PoDThesis_146(1).jpg';
import THESIS_GAL_7 from '../assets/images/thesis/5.7.21_PoDThesis_148.jpg';
import THESIS_GAL_7_T from '../assets/images/thesis/5.7.21_PoDThesis_148.jpg';
import THESIS_GAL_8 from '../assets/images/thesis/5.7.21_PoDThesis_152.jpg';
import THESIS_GAL_8_T from '../assets/images/thesis/5.7.21_PoDThesis_152(1).jpg';
import THESIS_GRID_1 from '../assets/images/thesis/Thesis-Wall-1-l.png';
import THESIS_GRID_1_T from '../assets/images/thesis/Thesis-Wall-1.png';
import THESIS_GRID_2 from '../assets/images/thesis/Thesis-Wall-2-l.png';
import THESIS_GRID_2_T from '../assets/images/thesis/Thesis-Wall-2.png';
import THESIS_GRID_3 from '../assets/images/thesis/Thesis-Wall-5-l.png';
import THESIS_GRID_3_T from '../assets/images/thesis/Thesis-Wall-5.png';
import THESIS_GRID_4 from '../assets/images/thesis/Thesis-Wall-6-l.png';
import THESIS_GRID_4_T from '../assets/images/thesis/Thesis-Wall-6.png';
import THESIS_GRID_5 from '../assets/images/thesis/Thesis-Wall-9-l.jpeg';
import THESIS_GRID_5_T from '../assets/images/thesis/Thesis-Wall-9.jpg';
import THESIS_GRID_6 from '../assets/images/thesis/Thesis-Wall-7-l.png';
import THESIS_GRID_6_T from '../assets/images/thesis/Thesis-Wall-7.png';
import THESIS_GRID_7 from '../assets/images/thesis/Thesis-Wall-8-l.png';
import THESIS_GRID_7_T from '../assets/images/thesis/Thesis-Wall-8.png';
import THESIS_GRID_8 from '../assets/images/thesis/Thesis-Wall-10-l.jpeg';
import THESIS_GRID_8_T from '../assets/images/thesis/Thesis-Wall-10.jpg';
import THESIS_GRID_9 from '../assets/images/thesis/Thesis-Wall-14-l.png';
import THESIS_GRID_9_T from '../assets/images/thesis/Thesis-Wall-14.png';
import THESIS_GRID_10 from '../assets/images/thesis/Thesis-Wall-15-l.png';
import THESIS_GRID_10_T from '../assets/images/thesis/Thesis-Wall-15.png';
import THESIS_GRID_11 from '../assets/images/thesis/Thesis-Wall-16-l.png';
import THESIS_GRID_11_T from '../assets/images/thesis/Thesis-Wall-16.png';
import THESIS_GRID_12 from '../assets/images/thesis/Thesis-Wall-17-l.jpeg';
import THESIS_GRID_12_T from '../assets/images/thesis/Thesis-Wall-17.jpg';
import TIMESCAPE_1 from '../assets/images/thesis/Timescape-product-2.png';
import TIMESCAPE_2 from '../assets/images/thesis/Timescape-product-3.png';
import TIMESCAPE_3 from '../assets/images/thesis/Timescape-campaign-1.png';
import TIMESCAPE_4 from '../assets/images/thesis/Timescape-campaign-2.png';
import TIMESCAPE_5 from '../assets/images/thesis/timescape-campaign-3.png';
import TIMESCAPE_6 from '../assets/images/thesis/timescape-campaign-4.png';
import UNWIND_1 from '../assets/images/thesis/Unwind-product-2.png';
import UNWIND_2 from '../assets/images/thesis/unwind-product-3.png';
import UNWIND_3 from '../assets/images/thesis/unwind-process-2.jpg';
import UNWIND_4 from '../assets/images/thesis/Unwind-workshop-1.png';
import UNWIND_5 from '../assets/images/thesis/Unwind-workshop-2.png';
import UNWIND_6 from '../assets/images/thesis/Unwind-workshop-3.png';
import UNWIND_7 from '../assets/images/thesis/Unwind-workshop-4.png';
import PHASER_1 from '../assets/images/thesis/phaser-product-3.png';
import PHASER_2 from '../assets/images/thesis/phaser-product-4.png';
import PHASER_3 from '../assets/images/thesis/phaser-product-5.png';
import PHASER_4 from '../assets/images/thesis/Phaser-process-2.png';
import PHASER_5 from '../assets/images/thesis/phaser-process-3.png';
import PHASER_6 from '../assets/images/thesis/phaser-process-4.png';
import PHASER_7 from '../assets/images/thesis/phaser-process-5.png';
import SILLY_1 from '../assets/images/thesis/Silly-product-2.png';
import SILLY_2 from '../assets/images/thesis/Silly-product-3.png';
import SILLY_3 from '../assets/images/thesis/Silly-product-4.png';
import SILLY_4 from '../assets/images/thesis/Silly-product-5.png';
import SILLY_5 from '../assets/images/thesis/Silly-product-6.png';
import SILLY_6 from '../assets/images/thesis/Silly-product-7.png';
import ClGrid from "../components/ClGrid";
import {Link} from "react-router-dom";

const images = [
    {
        original: THESIS_GAL_1,
        thumbnail: THESIS_GAL_1_T,
    },
    {
        original: THESIS_GAL_2,
        thumbnail: THESIS_GAL_2_T,
    }
];
const silly_images = [
    {
        original: SILLY_1,
        thumbnail: SILLY_1,
    },
    {
        original: SILLY_2,
        thumbnail: SILLY_2,
    },
    {
        original: SILLY_3,
        thumbnail: SILLY_3,
    },
    {
        original: SILLY_4,
        thumbnail: SILLY_4,
    },
    {
        original: SILLY_5,
        thumbnail: SILLY_5,
    },
    {
        original: SILLY_6,
        thumbnail: SILLY_6,
    }

];
const unwind_images = [
    {
        original: UNWIND_1,
        thumbnail: UNWIND_1,
    },
    {
        original: UNWIND_2,
        thumbnail: UNWIND_2,
    }
];
const unwind_workshop_images = [
    {
        original: UNWIND_3,
        thumbnail: UNWIND_3,
    },
    {
        original: UNWIND_4,
        thumbnail: UNWIND_4,
    },
    {
        original: UNWIND_5,
        thumbnail: UNWIND_5,
    }
];
const unwind_report_images = [
    {
        original: UNWIND_6,
        thumbnail: UNWIND_6,
    },
    {
        original: UNWIND_7,
        thumbnail: UNWIND_7,
    }
];
const phaser_images = [
    {
        original: PHASER_1,
        thumbnail: PHASER_1,
    },
    {
        original: PHASER_2,
        thumbnail: PHASER_2,
    },
    {
        original: PHASER_3,
        thumbnail: PHASER_3,
    }
];
const phaser_grid = [
    {
        original: PHASER_4,
        thumbnail: PHASER_4,
    },
    {
        original: PHASER_5,
        thumbnail: PHASER_5,
    },
    {
        original: PHASER_6,
        thumbnail: PHASER_6,
    },
    {
        original: PHASER_7,
        thumbnail: PHASER_7,
    }
];

const timescape_images = [
    {
        original: TIMESCAPE_1,
        thumbnail: TIMESCAPE_1,
    },
    {
        original: TIMESCAPE_2,
        thumbnail: TIMESCAPE_2,
    },
    {
        original: TIMESCAPE_3,
        thumbnail: TIMESCAPE_3,
    },
    {
        original: TIMESCAPE_4,
        thumbnail: TIMESCAPE_4,
    },
    {
        original: TIMESCAPE_5,
        thumbnail: TIMESCAPE_5,
    },
    {
        original: TIMESCAPE_6,
        thumbnail: TIMESCAPE_6,
    }
];
const thesis_grid_images = [
    {
        original: THESIS_GRID_1,
        thumbnail: THESIS_GRID_1_T,
    },
    {
        original: THESIS_GRID_2,
        thumbnail: THESIS_GRID_2_T,
    },
    {
        original: THESIS_GRID_3,
        thumbnail: THESIS_GRID_3_T,
    },
    {
        original: THESIS_GRID_4,
        thumbnail: THESIS_GRID_4_T,
    },
    {
        original: THESIS_GRID_5,
        thumbnail: THESIS_GRID_5_T,
    },
    {
        original: THESIS_GRID_6,
        thumbnail: THESIS_GRID_6_T,
    },
    {
        original: THESIS_GRID_7,
        thumbnail: THESIS_GRID_7_T,
    },
    {
        original: THESIS_GRID_8,
        thumbnail: THESIS_GRID_8_T,
    },
    {
        original: THESIS_GRID_9,
        thumbnail: THESIS_GRID_9_T,
    },
    {
        original: THESIS_GRID_10,
        thumbnail: THESIS_GRID_10_T,
    },
    {
        original: THESIS_GRID_11,
        thumbnail: THESIS_GRID_11_T,
    },
    {
        original: THESIS_GRID_12,
        thumbnail: THESIS_GRID_12_T,
    }
];
const thesis_images = [
    {
        original: THESIS_GAL_1,
        thumbnail: THESIS_GAL_1_T,
    },
    {
        original: THESIS_GAL_2,
        thumbnail: THESIS_GAL_2_T,
    },
    {
        original: THESIS_GAL_3,
        thumbnail: THESIS_GAL_3_T,
    },
    {
        original: THESIS_GAL_4,
        thumbnail: THESIS_GAL_4_T,
    },
    {
        original: THESIS_GAL_5,
        thumbnail: THESIS_GAL_5_T,
    },
    {
        original: THESIS_GAL_6,
        thumbnail: THESIS_GAL_6_T,
    },
    {
        original: THESIS_GAL_7,
        thumbnail: THESIS_GAL_7_T,
    },
    {
        original: THESIS_GAL_8,
        thumbnail: THESIS_GAL_8_T,
    }
];

class Thesis extends Component{
    render(){
        return(
            <div>
            <Container fluid className="g-0">
                <Row className="hero-container">
                    <Col>
                        <img src={THESIS_HERO} className="img-fluid thesis-background"/>
                    </Col>
                </Row>
            </Container>
                <Container>
                    <Row>
                        <Col lg={5}>
                            <h2>Reframing our relationship with time towards choice.</h2>
                        </Col>
                        <Col>
                            <p>My thesis, Overwhelmed: Work, Play, Time, and Design, investigates Time Poverty in the context of knowledge workers. Defined as the state or condition in which an individual lacks the discretionary time required for activities to build their social and human capital, I assert that the underlying reason for Time Poverty is not the lack of time, but the lack of choice. Roughly 80% of American knowledge workers feel that they never have enough time to do the things they want to, and collectively fail to utilize approximately 700 million vacation days each year. Startled by the magnitude of this fact, I began to explore our complicated relationship with time.</p>
                        </Col>
                    </Row>
                    <Row className="hero-image">
                        <Col lg={10} className="ms-auto me-auto text-center">
                            <iframe src="https://player.vimeo.com/video/549523981?h=8938cb5a04&amp;app_id=122963"
                                    width="1004" height="565" frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture" allowFullScreen=""
                                    title="Karan Mahendra Bansal: Overwhelmed: Work, Play, Time, and Design"
                                    id="yui_3_17_2_1_1642311025132_77"></iframe>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={10} className="ms-auto me-auto text-center hero-quote">
                            <h4>Roughly 80% of American knowledge workers feel that they never have enough time to do the things they want to, and collectively fail to utilize approximately 700&nbsp;million vacation days&nbsp;each&nbsp;year.</h4>
                        </Col>
                    </Row>
                    <Row >
                        <Col lg={10} className="ms-auto me-auto">
                            <p>The clock, which I call “a designed object of power,” is used to standardize and influence how we choose what to do. Choice is not only a matter of the individual, but is also responsive to environmental factors that include a web of ecological forces—social, economic, political, and media-related—that co-create how we interface with choices in life. By understanding this, my research and products of design explore how individuals can be more intentional when deciding how to spend time within their digital lives, work lives, and personal lives.</p>
                        </Col>
                    </Row>
                    <Row className="my-md-4-5">
                        <Col lg={10} className="ms-auto me-auto text-center">
                            <iframe allow="autoplay; fullscreen" scrolling="no" data-image-dimensions="1004x627"
                                    allowFullScreen="true"
                                    src="https://cdn.embedly.com/widgets/media.html?src=%2F%2Fe.issuu.com%2Fembed.html%230%2F88670432&amp;display_name=Issuu&amp;url=https%3A%2F%2Fissuu.com%2Fkbansal%2Fdocs%2Foverwhelmed-v2&amp;image=https%3A%2F%2Fimage.issuu.com%2F211016191831-2da4f305ce91a6c807143a48201d48f8%2Fjpg%2Fpage_1_thumb_medium.jpg&amp;key=61d05c9d54e8455ea7a9677c366be814&amp;type=text%2Fhtml&amp;schema=issuu&amp;wmode=opaque"
                                    width="1004" data-embed="true" frameBorder="0" title="Issuu embed"
                                    className="embedly-embed" height="627"></iframe>
                        </Col>
                    </Row>
                    <Row >
                        <Col lg={10} className="ms-auto me-auto">
                            <p>The suite of products range from speculative designs that explore how we can showcase the difference between clock-time and time as we experience it; to platforms that give us control over the constant digital interruptions into our non-digital lives; to physical products that visually aid us in managing our choices; to services that subvert existing encroachments on choice and create cultures of play, allowing us to manifest pure forms of unmediated individual choice.</p>
                        </Col>
                    </Row>
                    <Row className="my-md-4-5">
                        <Col lg={7} className="ms-auto me-auto text-center">
                            <div>
                            <ImageGallery items={thesis_images} showFullscreenButton={false} autoPlay={true} showPlayButton={false}/>
                            </div>
                        </Col>
                    </Row>
                    <Row className="image-container">
                        <ClGrid images={thesis_grid_images} cols={4} aspectRatio="1x1"></ClGrid>
                    </Row>
                    <hr/>
                    <Row>
                    <Col lg={10} className="ms-auto me-auto my-md-4-5">
                        <h3 className="text-muted">SELECTED PROJECTS</h3>
                    </Col>
                    </Row>
                    <Row>
                        <Col lg={10} className="ms-auto me-auto">
                            <h4>Timescape</h4>
                            <p>Timescape is a speculative neural hacking kit that aims to demonstrate that time is a construct of the brain. It consists of injectable proteins that temporarily alter the brain's neural plasticity, which changes various perceptions of time—like sequence, direction, change, and duration.  </p>
                            <img src={TIMESCAPE_HERO} className="img-fluid"/>
                            <p>Timescape is intended as a bio-hack that users can obtain at the nearest pharmacy. Karan says the speculative product is intended to "provoke a conversation around the conflict between time as we experience it and the constructs of time like linearity, non-elasticity, and continuity that ‘clock time’ imposes. It challenges the status quo of time that is external and independent to time that is subjective and experiential." The Timescape kit consists of four shots, each of which is responsible for changing a specific perception. Each syringe consists of a protein formulation that modulates the synaptic stimuli responsible for mechanisms responsible for creating the respective construct in the brain.</p>
                            <img src={TIMESCAPE_GIF} className="img-fluid"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={10} className="ms-auto me-auto image-container">
                            <Row>
                                <ClGrid images={timescape_images} cols={2} aspectRatio="3x2"></ClGrid>
                            </Row>
                            <Link to="/timescape#" className="externalLink">Learn more about the project</Link>
                            <hr/>
                            <Row className="my-md-4-5">
                                <h4>Unwind</h4>
                                <p>Unwind is a notification management tool built into iOS that helps users manage their attention overload, without fear of missing an important notification. It helps users understand and take control of how their phones interrupt them. It does so by allowing users to selectively filter notifications based on source and content, then delivering the notifications only in their desired context—for example, at a certain time, in a certain location, or when a user is performing a certain action. Unwind utilizes the Apple Push Notification Service and Artificial Intelligence to classify notifications, as well as a host of internet of things and third-party integrations to align the notifications in their desired context. Karan’s work explores how design might provide the individual greater control over their digital attention ecology in the Age of Information Economy. </p>
                                <img src={UNWIND_HERO} className="img-fluid"/>
                               <Row className="image-container">
                                   <Col className="ms-auto me-auto text-center">
                                           <iframe width="960" height="540" src="https://www.youtube.com/embed/4lqF615wJlM"
                                                   title="YouTube video player" frameBorder="0"
                                                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                   allowFullScreen></iframe>
                                   </Col>
                               </Row>

                                <p>When creating Unwind, Karan realized that current solutions to deal with interruptions from phones provided only an on/off control, forcing the user to choose between being interrupted frequently or potentially missing out on important communications by turning notifications off.</p>
                               <div className="image-container">
                                <ImageGallery items={unwind_images} originalHeight="445" originalWidth="668" autoPlay={true} showFullscreenButton={false} showThumbnails={false} showNav={true} showPlayButton={true}/>
                               </div>
                                <p>With Unwind, however, users can head to the new Unwind section of their phone settings to understand the various app-specific types of notifications. Users can also analyze how these notifications interrupt them and then take appropriate action by selecting various filters offered natively in-app and by iOS. To make things effortless, one could also simply ask Siri. For example, one could enable only New York Times Breaking News notifications while they’re working and schedule the rest of their New York Times notifications to arrive while they’re getting their morning coffee. After returning home from work, they could filter out all messages from their boss except those that contain a Call to Action.</p>
                                <Row className="image-container">
                                    <Col className="ms-auto me-auto text-center">
                                            <iframe width="960" height="540" src="https://www.youtube.com/embed/A2XzD0jIDOg"
                                                    title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen></iframe>
                                    </Col>
                                </Row>
                                <p className="my-md-4-5">During the research phase of his thesis, Karan hosted an online experiment where he invited participants to set up notification trackers on their phones. Together they analyzed the distribution, content, and context of notifications, articulated problem needs, and brainstormed possible solutions. </p>
                                <Row className="image-container">
                                    <ClGrid images={unwind_workshop_images} cols={3} aspectRatio="1x1"></ClGrid>
                                </Row>
                                <div className="image-container">
                                <ImageGallery items={unwind_report_images} originalHeight="445" originalWidth="668" autoPlay={true} showFullscreenButton={false} showThumbnails={false} showNav={true} showPlayButton={true}/>
                                </div>
                            </Row>
                            <Link to="/unwind" className="externalLink">Learn more about the project</Link>
                            <hr/>
                            <Row className="my-md-4-5">
                                <h4>Phaser</h4>
                                <p>Phaser is a personal productivity system that helps knowledge workers visualize their work, transforming their conceptual and threatening workload into actionable, context-sensitive flow. It promotes subjectivity in task management by enabling the user to understand the value of their choices. </p>
                                <Row className="image-container">
                                    <Col className="ms-auto me-auto text-center">
                                        <iframe width="960" height="540" src="https://www.youtube.com/embed/XUWoW9pyTs4"
                                                title="YouTube video player" frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen></iframe>
                                    </Col>
                                </Row>
                                <img src={PHASER_HERO} className="img-fluid"/>
                                <p>This project was envisioned to counter the increasing mechanization of the worker due to obsession with time management. Karan extrapolated what choice of how we spend our time in the office might look like in the deep future, by creating a newspaper article advertising a speculative neural synchronizer that would allow employees to directly synchronize their company’s calendar to their brain. Such a product posed the question: How different is “what calendar is to Man” from “what code is to machine”?</p>
                                <img src={PHASER_PAPER} className="img-fluid"/>
                                <p>Upon talking to various subject matter experts in the field of Organizational Psychology, Karan learned that the obsession with time management takes away the agency of the worker. To prevent this, he was inspired to create a personal Kanban-style tool that allows users to approach choices and time more subjectively. Phaser helps the user in three ways:
                                    <br/>
                                    <br/>
                                    <ol>
                                        <li>Visualizing and materializing choices in tactile ways to make the user more aware of their choices</li>
                                        <li>Representing connections between events to visually link the cause and effect between various choices</li>
                                        <li>Visually demarcating priorities to help the user understand and adapt to changing priorities</li>
                                    </ol>
                                    </p>
                                <div className="image-container">
                                <ImageGallery items={phaser_images} originalHeight="445" originalWidth="668" autoPlay={true} showFullscreenButton={false} showThumbnails={false} showNav={false} showPlayButton={false}/>
                                </div>
                                <p>Phaser consists of colored sets of dry-erase writable surfaces, each color representing a different category of task. Potential categories include Finance, Analytics, and Legal, or Personal, Professional, and Social. Each category contains three distinct shapes, where the number of peaks denotes the distinct priorities of work. These tasks attach magnetically to the structure, and the retractable chords allow the user to have a visual representation of the flow of events. By giving users a self-initiated, tactile, and visual representation of their tasks, flows, and priorities, Phaser encourages them to be more subjective about their choices.</p>
                                <img src={PHASER_DESK} className="img-fluid"/>
                                <Row className="clGridContainer">
                                    <ClGrid images={phaser_grid} cols={2} aspectRatio="16x9" />
                                </Row>
                                <Link to="/phaser" className="externalLink">Learn more about the project</Link>
                            </Row>
                            <hr/>
                            <Row className="my-md-4-5">
                                <h4>Hiatus</h4>
                                <p>Hiatus is an iOS feature that helps define work-life boundaries for knowledge workers in the constantly connected and mobile workplace. It helps users maintain work-life balance by subverting productivity and injecting play into their otherwise work-oriented phones during non-work hours. The feature is intended to cultivate cultures of play in our personal lives, while containing the work cultures that moralize productivity and frame the narrative of how time is supposed to be spent.</p>
                                <img src={HIATUS_HERO} className="img-fluid"/>
                                <Row className="image-container">
                                    <Col className="ms-auto me-auto text-center">
                                        <div>
                                            <iframe  width="960" height="540" src="https://www.youtube.com/embed/YZUgF_8N7iQ"
                                                    title="YouTube video player" frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen></iframe>
                                        </div>
                                    </Col>
                                </Row>
                                <p>Once enabled, Hiatus replaces the quantitative interfaces with ones geared toward qualitative fun, allowing the user opportunities to spend their time more subjectively. The user can configure their non-work hours in the settings and select the apps they want to switch. Once the non-work hour starts, Hiatus replaces the clock on the Lock Screen with fun, time-based activities they could do. On the Home screen, Hiatus hides the productivity apps within the App Library and replaces them with fun counterparts.</p>
                                <img src={HIATUS_1} className="img-fluid"/>
                                <p>For example, Zoom is replaced with Houseparty, Mail is replaced with Yo, Stocks with Breathe, and Trello with Solitaire. Hiatus returns the interfaces back to normal at the start of the next workday.</p>
                                <Row className="image-container">
                                    <Col className="ms-auto me-auto text-center">
                                        <iframe width="960" height="540" src="https://www.youtube.com/embed/3UCa-DtuAKc"
                                                title="YouTube video player" frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen></iframe>
                                        <iframe width="960" height="540" src="https://www.youtube.com/embed/MpFkxxNsZF4"
                                                title="YouTube video player" frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen></iframe>
                                    </Col>
                                </Row>
                            </Row>
                            <Link to="/hiatus" className="externalLink">Learn more about the project</Link>
                            <hr/>
                            <Row className="my-md-4-5">
                                <h4>Silly</h4>
                                <p>Silly is an employer-sponsored subscription box service created to cultivate a culture of active play amongst adults. It allows its subscribers to discover and subscribe to various play activities. It also facilitates engagement with fellow playmates by encouraging them to challenge each other, enabling them to make play a part of their daily routine. During childhood, play was our default state of being; Silly is envisioned to restore the act of play to that position. While exploring the state of choice in people’s personal lives, Karan found that the pandemic eroded the boundaries between work and home. As a result, people ended up working longer hours while working from home. His research indicated that even in the absence of a direct compulsion, people choose to do what they are told. While exploring ways to restore users’ choice, he identified the act of play as the ultimate expression of choice, for it is what one wants to do, not what one is obliged to do. </p>
                                <img src={SILLY_HERO} className="img-fluid"/>
                                <p>Users can discover activities by taking the short questionnaire on the website, answering questions about, for example, what types of play they enjoyed as kids, the multiple elements they enjoy within an activity, and their intention for play. Based on the answers, Silly determines a play personality and recommends activities users can subscribe to directly from the website. </p>
                                <div className="image-container">
                                <ImageGallery items={silly_images} originalHeight="445" originalWidth="668" autoPlay={true} showFullscreenButton={false} showThumbnails={false} showNav={false} showPlayButton={false}/>
                                </div>
                            </Row>
                            <hr/>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}
export default Thesis