import {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

class NotFound extends Component{
    render(){
        return(
            <Container fluid className="g-0">
                <Row className="about-page">
                    <Col md={10} className="ms-auto me-auto text-center">
                        <h4>🤭 Oops! There's nothing here <Link to="/" className="pointer">Go Back</Link></h4>
                    </Col>
                </Row>
            </Container>
        );
    }
}
export default NotFound