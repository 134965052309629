import {Component} from "react";
import {Carousel, Col, Container, Row} from "react-bootstrap";
import {ExternalLink} from "../components/ExternalLink";
import UNWIND_HERO_2 from "../assets/images/unwind/Unwind Demonstration.gif";
import UNWIND_3 from "../assets/images/unwind/unwind-product-3.png";
import UNWIND_BACKGROUND from "../assets/images/unwind/Unwind-product-2.png";
import UNWIND_5 from "../assets/images/unwind/Unwind-logo.png";
import UNWIND_6 from "../assets/images/unwind/Unwind-workshop-1.png";
import UNWIND_7 from "../assets/images/unwind/Attention-Residue.png";
import UNWIND_8 from "../assets/images/unwind/repercussions.png";
import UNWIND_9 from "../assets/images/unwind/Unwind-experiment-report-1.png";
import UNWIND_10 from "../assets/images/unwind/Unwind-experiment-report-2.png";
import UNWIND_11 from "../assets/images/unwind/unwind-experiment-report-3.png";
import UNWIND_12 from "../assets/images/unwind/Unwind-experiment.png";
import UNWIND_13 from "../assets/images/unwind/the-ecology-of-attention.jpg";
import UNWIND_14 from "../assets/images/unwind/Market-Oppurtunity.png";
import UNWIND_15 from "../assets/images/unwind/Filter-Align.gif";
import UNWIND_16 from "../assets/images/unwind/unwind-early-wires.png";
import UNWIND_17 from "../assets/images/unwind/unwind-early-wire-2.png";
import UNWIND_18 from "../assets/images/unwind/Prototypes-1.png";
import UNWIND_19 from "../assets/images/unwind/Prototypes-2.png";
import UNWIND_20 from "../assets/images/unwind/Variations-1.png";
import UNWIND_21 from "../assets/images/unwind/unwind-process-2.jpg";
import UNWIND_22 from "../assets/images/unwind/unclassified.png";
import UNWIND_23 from "../assets/images/unwind/Unwind-siri-nyt-office.png";

const workshop_gallery_1 = [
    {
        original: UNWIND_9,
        thumbnail: UNWIND_9,
    },
    {
        original: UNWIND_10,
        thumbnail: UNWIND_10,
    },
    {
        original: UNWIND_11,
        thumbnail: UNWIND_11,
    },
    {
        original: UNWIND_12,
        thumbnail: UNWIND_12,
    }
];
class Unwind extends Component{

    render(){
        return(
          <div>
              <Container fluid className="g-0">
                  <Row className="hero-container">
                      <Col>
                          <img src={UNWIND_BACKGROUND} className="img-fluid unwind_background"/>
                      </Col>
                  </Row>
              </Container>
              <Container>
                  <Row>
                      <Col lg={5}>
                          <h2>Amplifying individual control over ones digital attention&nbsp;ecology</h2>
                      </Col>
                      <Col>
                          <p>Unwind is a notification management tool built into iOS that helps users manage their attention overload, without fear of missing an important notification. It helps users understand and take control of how their phones interrupt them. It does so by allowing users to selectively filter notifications based on source and content, then delivering the notifications only in their desired context—for example, at a certain time, in a certain location, or when a user is performing a certain action. Unwind utilizes the Apple Push Notification Service and Artificial Intelligence to classify notifications, as well as a host of internet of things and third-party integrations to align the notifications in their desired&nbsp;context.</p>
                      </Col>
                  </Row>
                  <Row>
                      <Col lg={10} className="ms-auto me-auto text-center">
                          <div className="iframe-container">
                              <iframe className="responsive-iframe" width="960" height="540" src="https://www.youtube.com/embed/4lqF615wJlM"
                                      title="YouTube video player" frameBorder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen></iframe>
                          </div>
                      </Col>
                      <Col lg={10} className="ms-auto me-auto text-center hero-image">
                          {/*<img src={UNWIND_HERO_1} className="img-fluid"/>*/}
                          <img src={UNWIND_HERO_2} className="img-fluid"/>

                      </Col>
                  </Row>
                  <Row>
                      <Col lg={10} className="ms-auto me-auto text-center hero-quote">
                          <h4>Unwind was intended to provide the individual, greater choice and control over their digital attention ecology, enabling them to better align their phones' temporality with their lived temporality, instead of it being the other way round.</h4>
                      </Col>
                  </Row>
                  <Row>
                      <Col lg={10} className="ms-auto me-auto">
                          <Row className="overviewContainer">
                              <Col>
                                  <h3 className="text-muted">OVERVIEW</h3>
                                  <hr></hr>
                                  <Row>
                                      <Col lg={6}>
                                          <h5 >About</h5>
                                          <p>Unwind is the digital instantiation of my MFA Thesis — Overwhelmed: Work, Play, Time, and Design — an exploration into time-poverty, colonialist origins of timekeeping, and ways to augment time that restore choice. The project was done as part of the Designing for Screens course at <u><i><ExternalLink url="https://productsofdesign.sva.edu/" label="MFA Products of Design" className="pointer"/></i></u>, School of Visual Arts taught by <u><i><ExternalLink url="http://krissixenakis.com" label="Krissi Xenakis" className="pointer"/></i></u>.</p>
                                      </Col>
                                      <Col lg={6}>
                                          <Row>
                                              <Col lg={3}>
                                                  <h5 >Duration</h5>
                                                  <p>10 Weeks (2021)</p>
                                              </Col>
                                              <Col lg={5}>
                                                  <h5 >Responsibilities</h5>
                                                  <p>Problem Definition
                                                      <br></br>
                                                      User Research
                                                      <br></br>
                                                      Workshop Facilitation
                                                      <br></br>
                                                      Product Strategy
                                                      <br></br>
                                                      Product Design
                                                      <br></br>
                                                      User Testing
                                                  </p>
                                              </Col>
                                              <Col lg={4}>
                                                  <h5 >Tools</h5>
                                                  <p>Figma
                                                      <br></br>
                                                      Tableau
                                                      <br></br>
                                                      IFTTT
                                                      <br></br>
                                                      Wolfram Cloud
                                                      <br></br>
                                                      Miro
                                                      <br></br>
                                                      Premiere Pro
                                                      <br></br>
                                                      User Testing
                                                  </p>
                                              </Col>
                                          </Row>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col>
                                          <hr></hr>
                                          <h5>The Problem</h5>
                                          <p>Currently, the average knowledge worker gets interrupted 120 times during the
                                              day by their phones, 80% of which are trivial. Around 40% of them end up getting less than 30 min of straight focused time for their work. Companies like Apple, Google acknowledge the problem and offer a fairly binary solution of enabling to turn off all notifications. But this binary view of attention completely ignores how embedded and integrated we are with our phones. In the age of Information Economy, without constant connectivity there lingers a constant repurcussion and fear of missing out i.e missing that one important Slack notification or missing that one latest tweet that could ruin the presentation or derail ones investment.
                                              <br></br>
                                              <br></br>
                                              <b> How can we then enable employees in digital workplaces who struggle to find chunks of distraction-free time to work in a way that does not interfere with their state(flow) without letting them miss out&nbsp;on&nbsp;anything?</b>  </p>
                                      </Col>
                                      <Col>
                                          <hr></hr>
                                          <h5>The Opportunity</h5>
                                          <p>To design a way that provides knowledge workers more choice in controlling What, When and How their digital distractions interrupt them and enables them to make informed decisions to better align their digital environments atemporality with their flow.</p>
                                      </Col>
                                  </Row>
                              </Col>
                          </Row>
                          <img src={UNWIND_5} className="img-fluid"/>
                          <h3 className="text-muted">PROCESS</h3>
                          <h4>The facts that started it all</h4>
                          <p>While exploring how we choose to spend our time in our digital lives, I found that the average knowledge worker gets interrupted 120 times during the day by their phones, 80% of which are trivial. This constant context switching leads to what researchers call “attention residue.” Studies estimate the switch cost, which is the time taken to regain focus after
                              a distraction, to be around 23 minutes, even for notifications that they ignored. This fractured mindscape often leads
                              to what Brigid Schulte terms “time confetti” which is the fragmentation of time to the point that it becomes hard to use. This may sound exaggerated, but it collectively adds up to something more
                              pernicious than we would expect. The same knowledge worker spends an average of just three minutes before switching tasks and gets barely 72 minutes of uninterrupted discretionary time during the entire day. This is in stark contrast to the ever-increasing requirement that knowledge workers
                              be able to focus and to handle increasingly complex information. </p>
                          <img name="attention-residue" src={UNWIND_7} className="img-fluid"/>
                          <p>The two major mobile phone platforms acknowledge this problem and offer
                              the solution of turning off notifications. Despite these offering, we have contrasting culture where in Around 67% of people check their phones for messages or alerts even when there hasn’t been a ping. Over 50% of people overestimate the sense of urgency required to respond to notifications, and only 13% of people are able to justify being unplugged to their bosses. Clearly this binary view of attention ignores how embedded and integral phones are in our lives and disregards the repercussions their users have when they are using the provided solution. Repercussions like missing out on being in the boss’ good graces if we miss that one important email, or missing out on the memories of people on social media if we are not present in the action and updated on others activities. </p>
                          <img name="attention-residue" src={UNWIND_8} className="img-fluid"/>
                          <h4>Co-Creation</h4>
                          <p>To better understand the dichotomy between attention overload and fear
                              of missing out, I conducted an online experiment and co-creation workshop, where I invited participants who classified themselves as knowledge workers and identified with the problem
                              of constant interruptions. Each participant had a different ritual before work and we mapped the common threads in the meta-journey each of them underwent from getting ready to work to post-completion of work. </p>
                          <img name="attention-residue" src={UNWIND_6} className="img-fluid"/>
                          <p>I then set up notification trackers using IFTTT and Wolfram Data Drop in participants’ phones for a workday. With these trackers, we were able to analyze
                              their interruptions based on their frequency, distribution, content, and the participants’ context and desired state.</p>
                          <Row className="image-container">
                              <Col>
                                  <div >
                                      <Carousel variant="dark" interval={2000}>
                                          <Carousel.Item>
                                              <img src={UNWIND_11} className="d-block w-100"/>
                                          </Carousel.Item>
                                          <Carousel.Item>
                                              <img src={UNWIND_9} className="d-block w-100"/>
                                          </Carousel.Item>
                                          <Carousel.Item>
                                              <img src={UNWIND_10} className="d-block w-100"/>
                                          </Carousel.Item>
                                          <Carousel.Item>
                                              <img src={UNWIND_12} className="d-block w-100"/>
                                          </Carousel.Item>
                                      </Carousel>
                                      {/*<ImageGallery items={workshop_gallery_1} showFullscreenButton={false} autoPlay={true} showPlayButton={true} showThumbnails={false}/>*/}
                                  </div>
                              </Col>
                          </Row>
                          <p>Through these activities, we learnt that the perceived notion of what interrupts us was completely misaligned with what was actually interrupting. We learnt that the <b>ability to pay attention was not only a matter of individual concern, but just like habits, was more responsive to environmental factors.</b> This was reminiscent of their attention ecology as outlined by Yves Citton in his book The Ecology of Attention:</p>
                          {/*<p>Through these activities, we mapped out the problem needs and surmised that <b>it wasn’t information overload, but rather a failure to filter the notifications and align them in their desired context.</b> This key insight scoped and framed the direction for my design.*/}
                          {/*    <br></br>*/}

                          {/*</p>*/}
                          <Row className="my-md-4-5">
                              <Col md={3} className="ms-auto">
                                  <img src={UNWIND_13} className="img-fluid"/>
                              </Col>
                              <Col md={7} className="my-auto ms-auto">
                                  <blockquote className="blockquote">
                                      <p>In modern society, attention is not a private experience;
                                          attention is an individual experience fashioned by collective forces.</p>
                                      <footer className="blockquote-footer">Yves Citton</footer>
                                  </blockquote>
                              </Col>
                          </Row>
                        <p>Through the online experiment, it also became imperative that participants wanted to direct their attention towards different areas in different contexts— for example, at a certain time, in a certain location, or when a user was performing some action. We synthesized that <b>it wasn't Information-Overload, but a failure to filter it</b> with the existing offerings. My market scan revealed that there was a missed opportunity for an intervention that allowed a greater variety of control over a broader range of notifications.</p>
                          <img name="attention-residue" src={UNWIND_14} className="img-fluid"/>
                          <h4>Ideation</h4>
                          <p>The insights from co-creation formed the basis of my design direction and prompted me to essentially devise an intervention that would allow knowledge workers to:</p>
                          <ol>
                              <li>Selectively filter notifications</li>
                              <li>Align notifications within their desired context</li>
                              <li>Aid in making informed decisions about controlling their notifications</li>
                          </ol>
                          <p>I started creating an inventory of ways of classifying a notification, attributes that constituted a desired context and began looking at ways this intervention could be interjected within the existing&nbsp;systems.</p>
                          <img name="attention-residue" src={UNWIND_15} className="img-fluid"/>
                          <p>I identified an opportunity space within the Apple Push Notification System and started exploring an interface that would allow users to manage their notifications on a granular&nbsp;level.</p>
                          <img name="attention-residue" src={UNWIND_16} className="img-fluid"/>
                          <p>Staying within the iOS Design guidelines, I started prototyping in Figma the various interactions a user would perform when getting notified and ways they would provide inputs to filter notifications multi-dimensionally.</p>
                          <Row className="image-container">
                              <Col>
                                  <div>
                                      <Carousel variant="dark" interval={3000}>
                                          <Carousel.Item>
                                              <img src={UNWIND_18} className="d-block w-100"/>
                                          </Carousel.Item>
                                          <Carousel.Item>
                                              <img src={UNWIND_17} className="d-block w-100"/>
                                          </Carousel.Item>
                                          <Carousel.Item>
                                              <img src={UNWIND_19} className="d-block w-100"/>
                                          </Carousel.Item>
                                      </Carousel>
                                      {/*<ImageGallery items={workshop_gallery_1} showFullscreenButton={false} autoPlay={true} showPlayButton={true} showThumbnails={false}/>*/}
                                  </div>
                              </Col>
                          </Row>
                          <h4 className="h4-below-caraousel">Iteration & Testing</h4>
                          <p>I tested each variation in with four knowledge workers, and asked survey questions to evaluate the prototypes based on their functionality and usability. I found that although some interfaces enabled providing the multi-dimensional array of choices as inputs better than the other, they added significant complexity in using the feature regularly.</p>
                          <img name="attention-residue" src={UNWIND_20} className="img-fluid"/>
                          <p>Providing multiple choices to potentially filter data on, created a complexity of N. Log(N) and the potential number of choices that could be executed were nC(n-1). Representing this multi-dimensional slicing of choices in a linear, 2-Dimensional interface required recursive interfaces which hurt the usability drastically. Removing the recursion meant potentially enabling fewer choices, defeating the purpose of the intervention. </p>
                          <p>To improve on usability, I started exploring Voice User interfaces. VUI's provide a great means of abstracting the complexity of the user intents to their respective handlers. To better visualize the complexity, I modelled the recursive possibilities of choices in Apple's SiriKit and created updated prototypes of user's interaction with Siri.</p>
                          <img name="attention-residue" src={UNWIND_21} className="img-fluid"/>
                          <img name="attention-residue" src={UNWIND_3} className="img-fluid"/>
                          <p>The added Voice User Interface provided a fairly natural means of providing inputs to the system and made the feature much like the pre-digital “olden” days where
                              a person would ask their personal assistant to filter their interruptions. </p>
                          <h4>Final Product</h4>
                          <p>The final product resulted in a notification management tool built into iOS that helps users manage their interruptions, without the fear of missing out on an important notification.</p>
                          <div className="iframe-container">
                              <iframe className="responsive-iframe" width="960" height="540" src="https://www.youtube.com/embed/4lqF615wJlM"
                                      title="YouTube video player" frameBorder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen></iframe>
                          </div>
                          <p>Users can head to the new Unwind section of their phone settings to understand the various app-specific types of notifications. Users can also analyze how these notifications interrupt them. Users can then take appropriate actions by selecting various filters offered natively in-app and by iOS to align the notifications within their desired context.</p>
                          <img name="attention-residue" src={UNWIND_BACKGROUND} className="img-fluid"/>
                          <p>Once the filters are applied, the user is notified only of those notifications that pass the various conditions applied. The rest of the notifications are delivered silently and can be accessed whenever the user unlocks the phone, within special notification tabs. The user could potentially mix and match conditions to achieve fairly granular control over
                              their interruptions.</p>
                          <img src={UNWIND_HERO_2} className="img-fluid"/>
                          <p> Since classification by Artifical Intelligence is not foolproof, the system displays the classification category label in the notification and provides additional controls to allow the user to correct the classifications. For notifications that the system is unable to classify, it allows them to pass through as it would a normal notification.</p>
                          <img src={UNWIND_22} className="img-fluid"/>
                          <p>To make things effortless, Unwind utilizes SiriKIT, enabling the user to simply ask Siri to do things. For example, one could enable only New York Times Breaking News
                              notifications while they’re working
                              and schedule the rest of their New York Times notifications to arrive while they’re getting their morning coffee. Or after returning home from work, they could filter out all messages from their boss except those that contain a Call to Action.</p>
                          <img name="attention-residue" src={UNWIND_23} className="img-fluid"/>

                          <p>With Unwind, the individual has a greater choice and control over their digital attention ecology, enabling them to better align their phones’ temporality with their lived temporality.</p>

                          <h4>Takeaways</h4>
                          <p>Developing and refining the concept provided me the opportunity to co-create with my users and instilled in me a process that enabled translating learning objectives into workshop activities to collectively refine, reframe and build problem needs. It also refined my ability to balance design decisions between qualitative and quantitative research, often suggesting where to use each method and their respective weightage in evaluation, scoping and testing. By constraining myself to the existing design guidelines of iOS, I better appreciate the core patterns that build usability within design systems and the complexity they address. Going forward, I will continually seek opportunities to co-create with intended users to iteratively frame, reframe, ideate, and test solutions.</p>
                      </Col>
                  </Row>
              </Container>
          </div>
        );
    }
}
export default Unwind;