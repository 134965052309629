import {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import OVERWHELMED_BACKGROUND from "../assets/images/overwhelmed/Overwhelmed-hero.png"
import {ExternalLink} from "../components/ExternalLink";
import {Link} from "react-router-dom";
import OVERWHELMED_HERO from "../assets/images/overwhelmed/Hardcover-Hero.png";
import OVERWHELMED_1 from "../assets/images/overwhelmed/Opening-Quote.png"
import OVERWHELMED_2 from "../assets/images/overwhelmed/Spread-22-23.png";
import OVERWHELMED_3 from "../assets/images/overwhelmed/Spread-24-25.png";
import OVERWHELMED_4 from "../assets/images/overwhelmed/Spread-28-29.png";
import OVERWHELMED_5 from "../assets/images/overwhelmed/Spread-36-37.png";
import OVERWHELMED_6 from "../assets/images/overwhelmed/Spread-38-39.png";
import OVERWHELMED_7 from "../assets/images/overwhelmed/Spread-54-55.png";
import OVERWHELMED_8 from "../assets/images/overwhelmed/Spread-56-57.png";
import OVERWHELMED_9 from "../assets/images/overwhelmed/Spread-74-75.png";
import OVERWHELMED_10 from "../assets/images/overwhelmed/Spread-120-121-2.png";
import OVERWHELMED_11 from "../assets/images/overwhelmed/Spread-148-149.png";
import OVERWHELMED_12 from "../assets/images/overwhelmed/Spread-152-153.png";

class Overwhelmed extends Component{
    render(){
        return(
            <div>
                <Container fluid className="g-0">
                    <Row className="hero-container">
                        <Col>
                            <img src={OVERWHELMED_BACKGROUND} className="img-fluid"/>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col lg={5}>
                            <h2>Overwhelmed: Work, Play, Time, and Design</h2>
                        </Col>
                        <Col>
                            <p>Book design to articulate and communicate my <Link to="/thesis"><i>MFA Thesis</i>, Overwhelmed: Work, Play, Time, and Design</Link> — an ongoing commitment in subverting the controlling influence of the "Clock" in the daily lives of Knowledge Workers.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={10} className="ms-auto me-auto text-center hero-image">
                            <img src={OVERWHELMED_HERO} className="img-fluid"/>
                        </Col>
                    </Row>
                    <Row >
                        <Col lg={10} className="ms-auto me-auto text-center hero-quote">
                            <h4>The 180-page printed publication embodies the research, arguments, processes, strategies, methods, and design explorations of my year-long inquiry into Time&nbsp;Poverty in the context of Knowledge&nbsp;Workers.</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={10} className="ms-auto me-auto">
                            <img src={OVERWHELMED_1} className="img-fluid"/>
                            <Row className="image-container">
                            <Col lg={6}>
                                <img src={OVERWHELMED_2} className="img-fluid my-md-0"/>
                            </Col>
                            <Col lg={6}>
                                <img src={OVERWHELMED_3} className="img-fluid my-md-0"/>
                            </Col>
                            </Row>
                            <p>The book juxtaposes the historical, philosophical, political and cultural narrative of time with their manifestations in designed objects across airy layouts, which give the topics plenty of space to breathe.</p>
                            <img src={OVERWHELMED_4} className="img-fluid"/>
                            <img src={OVERWHELMED_5} className="img-fluid"/>
                            <img src={OVERWHELMED_6} className="img-fluid"/>
                            <Row className="image-container">
                                <Col lg={6}>
                                    <img src={OVERWHELMED_7} className="img-fluid my-md-0"/>
                                </Col>
                                <Col lg={6}>
                                    <img src={OVERWHELMED_8} className="img-fluid my-md-0"/>
                                </Col>
                            </Row>
                            <p>Receiving early feedback on the abstract and esoteric nature of the topic, I wanted the book to marry the literary rigor of a MFA Thesis with the approachability and calming nature of a coffee table book.</p>
                            <img src={OVERWHELMED_9} className="img-fluid"/>
                            <img src={OVERWHELMED_10} className="img-fluid"/>
                            <Row>
                                <Col lg={6}>
                                    <img src={OVERWHELMED_11} className="img-fluid"/>
                                </Col>
                                <Col lg={6}>
                                    <img src={OVERWHELMED_12} className="img-fluid"/>
                                </Col>
                            </Row>
                            <img src={OVERWHELMED_BACKGROUND} className="img-fluid"/>
                             <ExternalLink label="The book can be purchased here." url="https://www.blurb.com/b/11005480-overwhelmed"/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default Overwhelmed