import {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import HIATUS_BACKGROUND from "../assets/images/hiatus/Hiatus Lock Screen.gif";
import HIATUS_HERO from "../assets/images/hiatus/Hiatus App Switch.gif";

import HIATUS_1 from "../assets/images/hiatus/hiatus-logo-screen.png";
import HIATUS_2 from "../assets/images/hiatus/hiatus-screens.png";
import HIATUS_3 from "../assets/images/hiatus/Thesis-Wall-5.png";
import HIATUS_4 from "../assets/images/hiatus/time-use-2020-radial-v3-correct-data.png";
import HIATUS_6 from "../assets/images/hiatus/24-7.jpeg";
import HIATUS_7 from "../assets/images/hiatus/Activities.png";
import HIATUS_8 from "../assets/images/hiatus/Hiatus-wires.png";
import HIATUS_9 from "../assets/images/hiatus/HIatus onboarding.gif";
import HIATUS_10 from "../assets/images/hiatus/Hiatus-wires-2.png";
import HIATUS_12 from "../assets/images/hiatus/Hiatus Back to Normal.gif";
import {ExternalLink} from "../components/ExternalLink";

class Hiatus extends Component{

    render(){
        return(
            <div>
                <Container fluid className="g-0">
                    <Row className="hero-container">
                        <Col>
                            <img src={HIATUS_BACKGROUND} className="img-fluid hiatus_background"/>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col lg={5}>
                            <h2>Augmenting opportunities for play in 24/7 workplaces</h2>
                        </Col>
                        <Col>
                            <p>Hiatus is an experimental iOS feature that helps define work-life boundaries for
                                knowledge workers in the constantly connected and mobile workplace. Once enabled, Hiatus replaces the quantitative interfaces in iOS with ones geared towards qualitative fun, allowing the user opportunities to spend their time more subjectively.
                                <br></br>
                                <br></br>
                                It helps maintain work-life balance by subverting productivity and injecting novelty and play into their otherwise work oriented
                                phones during non-work hours.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={10} className="ms-auto me-auto text-center hero-image">
                            <img src={HIATUS_HERO} className="img-fluid"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={10} className="ms-auto me-auto text-center hero-quote">
                            <h4>The speculative feature intends to cultivate cultures of play in our personal lives, while containing the work cultures that moralize productivity and frame the narrative of how time is supposed to be spent.</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10} className="ms-auto me-auto">
                            <Row className="overviewContainer">
                                <Col>
                                    <h3 className="text-muted">OVERVIEW</h3>
                                    <hr></hr>
                                    <Row>
                                        <Col md={6}>
                                            <h5 >About</h5>
                                            <p>Hiatus is one of the digital instantiations of my MFA Thesis — Overwhelmed: Work, Play, Time, and Design — an exploration into time-poverty, colonialist origins of timekeeping, and ways to augment time that restore choice.

                                            </p>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Col md={6}>
                                                    <h5>Duration</h5>
                                                    <p>4 weeks</p>
                                                </Col>
                                                <Col md={6}>
                                                    <h5>Category</h5>
                                                    <p>Product Feature</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <h5>Tools</h5>
                                                    <p>Adobe XD, Illustrator, Keynote, Miro</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <hr></hr>
                            </Row>
                            <img src={HIATUS_1} className="img-fluid"/>
                            <img src={HIATUS_2} className="img-fluid"/>
                            <h3 className="text-muted">BACKGROUND</h3>
                            <p>When exploring how we choose to spend our
                                time at home, my secondary research highlighted
                                the fact that the onslaught of the pandemic has
                                reduced the distinctions between the boundaries of
                                work and home.</p>
                            <img src={HIATUS_3} className="img-fluid"/>
                            <p>Remote work increased the penetration of digital devices and mobilized work in our personal spaces. Coupled with the cultures at work that moralizes industrious productivity by rewarding us based on the time that we spend on economic activities, we are working longer hours from home and throughout day and night, completely obliterating the&nbsp;so called worklife&nbsp;balance.</p>
                            <img src={HIATUS_4} className="img-fluid"/>
                            <ExternalLink label="Source: Cycle of Many by Nathan Yau" url="https://flowingdata.com/2021/08/25/cycle-of-many/"/>
                            <p>This unintentional externalization of our choice of how we spend our time in the personal space is reminiscent of our condition as
                                outlined by Jonathan Crary in his book 24/7:
                                </p>
                            <Row className="my-md-4-5">
                                <Col md={3} className="ms-auto">
                                    <img src={HIATUS_6} className="img-fluid"/>
                                </Col>
                                <Col md={7} className="my-auto ms-auto">
                                    <blockquote className="blockquote">
                                        <p>We have become the innocuous, pliable inhabitants of
                                            global urban societies. Even in the absence of direct
                                            compulsion, we choose to do what we are told to
                                            do; we allow the management of our bodies, our
                                            ideas, our entertainment, and all our imaginary needs to be externally imposed.</p>
                                        <footer className="blockquote-footer">Jonathan Crary</footer>
                                    </blockquote>
                                </Col>
                            </Row>
                            <p>Our personal spaces represent the space
                                where we have the potential to exercise
                                our freedom without needing to abide
                                by the constraints of the work or the
                                collective. Yet we are bereft
                                of true freedom in our very own homes.
                                How can we then carve out a space
                                that lets us exercise the highest level of
                                personal choice?
                            </p>
                            <h5>How can products of design
                                prevent this externalization of
                                our agency in personal spaces?</h5>
                            <p>I started exploring these questions by
                                looking at ways in which we manifest
                                and express ourselves in our truest
                                capacity. I explored freedom not
                                through the lens of politics, but through
                                the lens of our day-to-day activities.</p>
                            <img src={HIATUS_7} className="img-fluid"/>
                            <ExternalLink label="Source: How We Spent Our Time in 2020 vs 2019 by Nathan Yau" url="https://flowingdata.com/2021/08/25/cycle-of-many/"/>
                            <p>During an interview with one of my subject matter experts, Liz Jackson and Alex Haagaard mentioned that there were activities where they completely lost sense of constraints and time when they went down their rabbit holes. They did them not because they were expected to do them, but purely for the sake of doing them.</p>
                            <Row className="my-md-4-5">
                                <Col md={7} className="ms-auto">
                                    <blockquote className="blockquote">
                                        <p>Play is the ultimate expression of choice. It’s
                                            when you go down those rabbit holes,
                                            that you loose sense of time and are
                                            truly with yourself in the present.</p>
                                        <footer className="blockquote-footer">Alex Haagaard & Liz Jackson</footer>
                                    </blockquote>
                                </Col>
                            </Row>
                            <p>With this insight, I started exploring opportunities for adults to engage in play during their non-work hours and ways of creating better visibility around the boundaries between work and play.</p>
                                <h5>Analyzing the various interfaces users experienced in their personal spaces, what stood out was the lack of variability in our mobile interfaces despite the diversity of the context they are subjected to — diversity amongst people, their activities, moods, subjectivities, and surroundings.</h5>
                                <p>Although the context completely shifts between a work meeting, a game of football, a movie-night, a date, our phones act the same, display the same data and subject us to the same homogenized interface, in-effect obliterating all boundaries. To counter this, I started conceptualizing mobile interfaces that would augment visibility of boundaries between professional and personal and reinforce choice in personal space by injecting opportunities to play.   </p>

                            <img src={HIATUS_8} className="img-fluid"/>
                            <img src={HIATUS_10} className="img-fluid"/>
                            <p>After multiple iterations, I ended up creating an interface that allowed users to switch the quantitative interfaces in iOS with qualitative fun counterparts, that would allow them to spend their time more subjectively.</p>
                            <h3 className="text-muted">PRODUCT</h3>
                            <p>Users can find Hiatus as a new section under the iOS Settings. On enabling Hiatus mode, the user can customize their work days and schedules. The user can then customize the apps they would like to Switch. Once done, the user is set.</p>
                            <Row className="hiatus-9-container">
                                <Col md={4} className="text-center ms-auto me-auto">
                                    <img src={HIATUS_9} className="img-fluid"/>
                                </Col>
                            </Row>

                            <p>Once the non-work hour starts, Hiatus replaces the clock on the Lock Screen with fun, time-based activities they could do.</p>
                            <img src={HIATUS_BACKGROUND} className="img-fluid"/>
                            <p>On the Home Screen, Hiatus hides the productivity apps within the App Library and replaces them with fun counterparts. For example, Zoom is replaced with
                                Houseparty, Mail is replaced with Yo,
                                Stocks with Breathe, and Trello with
                                Solitaire.</p>
                            <img src={HIATUS_HERO} className="img-fluid"/>
                            <p>Hiatus returns the interfaces
                                back to normal at the start of the next
                                workday.</p>
                            <img src={HIATUS_12} className="img-fluid"/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Hiatus;