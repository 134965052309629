import {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import BSERP_BACKGROUND from "../assets/images/bserp/userflowsdrib.gif";
import BSERP_HERO from "../assets/images/bserp/BSERP-Hero.001.png";
import BSERP_1 from "../assets/images/bserp/Login.002.png";
import BSERP_2 from "../assets/images/bserp/Dashboard.001.png";
import BSERP_3 from "../assets/images/bserp/sprint.png";
import BSERP_4 from "../assets/images/bserp/requirements.png";
import BSERP_5 from "../assets/images/bserp/overview-flow.png";
import BSERP_6 from "../assets/images/bserp/flat-hierarchy.png";
import BSERP_7 from "../assets/images/bserp/database.png";
import BSERP_8 from "../assets/images/bserp/Context.003.png";
import BSERP_9 from "../assets/images/bserp/BSERP-Books.jpg";
import BSERP_10 from "../assets/images/bserp/dependency.png";

class Bserp extends Component{

    render(){
        return(
            <div>
                <Container fluid className="g-0">
                    <Row className="hero-container">
                        <Col>
                            <img src={BSERP_BACKGROUND} className="img-fluid bserp-background"/>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col lg={5}>
                            <h2>Creating an employee first Enterprise Resource Planning software</h2>
                        </Col>
                        <Col>
                            <p>BS-ERP is a user-centered web-based Enterprise Resource Planning system for digitally uninitiated employees. It consists of 5 modules that simplify employees' everyday operations experience while catering to multiple Fortune 500 accounts. It has reduced their turnaround times to clients by 500% and offers a significant competitive advantage in service satisfaction.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={10} className="ms-auto me-auto text-center hero-image">
                            <img src={BSERP_HERO} className="img-fluid"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={10} className="ms-auto me-auto text-center hero-quote">
                            <h4>“The aim was to create a system that understood the employees, instead of one that needed&nbsp;to&nbsp;be&nbsp;understood.”</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={10}  className="ms-auto me-auto">
                            <Row className="overviewContainer">
                                <Col>
                            <h3 className="text-muted">OVERVIEW</h3>
                            <hr></hr>
                            <h5 className="note">Note: To meet non-disclosure agreement, screens, records of user journeys, business process flows, engineering processes and other information is obfuscated. Additional Information available on&nbsp;request.</h5>
                            <Row>
                                <Col lg={6}>
                                    <h5>The Brief</h5>
                                    <p>My employer, Bansal Scientific Equipments, provides consumables and scientific instruments to research and quality control laboratories across various industries based out of India. I was asked to increase revenue and bandwidth by streamlining everyday operations.</p>
                                </Col>
                                <Col lg={6}>
                                <Row>
                                    <Col>
                                        <h5>Duration</h5>
                                        <p>9 months (design + development)</p>
                                    </Col>
                                    <Col>
                                        <h5>Category</h5>
                                        <p>Digital Product Design</p>
                                    </Col>
                                </Row>
                                    <Row>
                                        <Col>
                                            <h5>Role</h5>
                                            <p>Design Strategist + Full Stack Engineer</p>
                                        </Col>
                                        <Col>
                                            <h5>Responsibilities</h5>
                                            <p>Design Research, User Research, UX/UI Design, Product Features, Front & Back end development, Testing, Deployment</p>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <hr/>
                                    <h5>The Challenge</h5>
                                    <p>Users handling everyday operations are digitally semi-illiterate. Managing all the existing generic software's tasks involved a lot of discrete processes and initiation from the user. Each step required an understanding of complex mental models from the user.</p>
                                </Col>
                                <Col>
                                    <hr/>
                                <h5>The Opportunity</h5>
                                    <p>To create user-flows that offered simpler mental models and initiation from users. </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <hr/>
                                    <h5>Business Goals</h5>
                                    <ol>
                                        <li>Increase Bandwidth of Order processing.</li>
                                        <li>Decrease mismanagement of Inventory.</li>
                                        <li>Reduce turnaround time for client-facing activities.</li>
                                    </ol>
                                </Col>
                                <Col>
                                    <hr/>
                                    <h5>Design Goals</h5>
                                    <ol>
                                        <li>Enable the user to focus on key business activities. </li>
                                        <li>Reduce reliance on user’s memory.</li>
                                        <li>Clearly communicate blockers and deadlines.</li>
                                    </ol>
                                </Col>
                                <Col>
                                    <hr/>
                                    <h5>Measuring Success</h5>
                                    <ol>
                                        <li>Reduce turnaround time for client-facing activities.</li>
                                        <li>Reduce inventory losses.</li>
                                        <li>Decrease time spent by user on ERP activities.</li>
                                        <li>Increase in revenue.</li>
                                    </ol>
                                </Col>
                            </Row>
                                </Col>
                            </Row>
                            <h4>The Risk</h4>
                            <p>As with any ERP implementation, there were several risks of porting a functioning office workflow to a completely new way of Business Processing. ERP is considered as the way an office performs, instead of just a tool. It defines the several changes in structure, power and workflows of work. Prior to deciding on the decision for implementing a custom suite, various existing ERP implementations were consulted. But the lack of appropriateness, cost and compromise on some Business Processes paved the way to undertake the following risks.</p>
                            <ol>
                                <li>Change and Cultural adoption</li>
                                <li>Failure to Redesign the Business Process to fit the software.</li>
                                <li>Lack of Integration</li>
                                <li>Project Scope and Timeline</li>
                                <li>Process Capability and Future State expectations</li>
                            </ol>
                            <img src={BSERP_1} className="img-fluid"/>
                            <h4>Approach: Keeping it Lean</h4>
                            <p>Prior to the instruction, we had undertaken a lengthy period of customer consultation and internal department interviews. During the course of the project we revisited research, questioning assumptions and features from the user's perspective.</p>
                            <Row className="my-md-4-5">
                                <Col className="ms-auto me-auto text-center">
                                    <img src={BSERP_3} className="img-fluid"/>
                                </Col>
                            </Row>
                            <p>I planned for small design and build cycles, scheduling regular testing and review periods. Learning as we went enabled us to guide the product development and meet more of the project goals.
                            <br/>
                                By holding cross-functional workshops we developed a better understanding of day-to-day challenges, drawing on everyone's experience to design innovative solutions.
                            </p>
                            <h4>Research</h4>
                            <p>I conducted several rounds of interviews collecting User’s problems, wishlists and the ideal scenarios. Some were targeted to collect the best case business practices from the senior management, requirements and expectations from customers.
                            <br/>
                                I started listing out requirements of different users into a consolidated wish list.
                            </p>
                            <img src={BSERP_4} className="img-fluid"/>
                            <p>
                                Some of the key insights I had was that <b>“Users referenced data from multiple sources and were often distracted by something before they were able to complete the task at hand.“</b>
                                <br/>
                                <br/>
                                Due to the nature of the business, users had to search through data across several catalogs across millions of products to find requested information. For any given product they would have as many as 20 attributes that they needed to check. Which had several real time information that needed to be validated from the buyers end before confirmation to the seller. This asynchronous step prolonged the typical workflow. The multitude of interruptions by clients phone calls, who often had a sense of urgency, disrupted the train of thoughts on their task at hand.
                                <br/>
                                <br/>
                                Another key insight I had was that <b>“The users lacked a conceptual model of the existing ERP system and often bypassed the suggested workflows due to their unfamiliarity with the process.”</b> The generic software was extremely rigid and mandated a series of data entry tasks in order to maintain correct records. There were many ways through which the users were able to get around the suggested workflows, often leading to bad data and subsequent confusion.
                                <br/>
                                <br/>
                                I modeled the key workflows in the existing system to map out its complexity and distributed nature and better understand users understanding of concept models.
                            </p>
                            <img src={BSERP_5} className="img-fluid"/>
                            <p>Navigating through the existing system involved several bottlenecks, coupled with the nature of business and data there was no way a traditional stack could support the required variation. </p>
                            <h4>Ideation</h4>
                            <p>I started modeling the required workflows and sitemap based on the users concept models of how their processes worked. This allowed me to flatten the Information Architecture at a visceral level and abstract all the complexities to the backend.  </p>
                            <img src={BSERP_6} className="img-fluid"/>
                            <p>Techniques were used to structure information within the application. Card sorting exercises helped advise on general information pages and discrete sections, while user stories assisted service mapping and determined appropriate system touch points.</p>
                            <img src={BSERP_7} className="img-fluid"/>
                            <h4>Design</h4>
                            <p>Considering the users requirement and flow as the constraints, I began developing simple UI devoid of any color and graphic elements to ease the transition for the users from a Keyboard based native application UI to a mouse based Web UI. The UI was constructed such that the user was reminded of the tasks, and required no initiation or dependency from their end. They could simply follow the instructions on screen for the task that needed to be done. </p>
                            <img src={BSERP_2} className="img-fluid"/>
                            <p>Contextual information was provided at various touch points, that minimized deviation and allowed for a seamless experience.</p>
                            <img src={BSERP_8} className="img-fluid"/>
                            <p>To achieve the variety of data and non traditional display of data, I referred to various texts and built a stack that used a combination of 3 databases. MySQL, MongoDB and Elasticsearch.  </p>
                            <img src={BSERP_9} className="img-fluid"/>

                            <h4>Beta Testing with Real Users</h4>
                            <p>
                                We input the data from the existing system and ran everyday options in parallel to highlight potential flaws and bugs.
                                <br/>
                                As a control experiment, we piloted the batch and AB tested against like for like events.
                                <br/>
                                Speaking to users provided valuable qualitative information and a good litmus test of how the changes had been received in terms of ease of operation and cognitive effort.
                                <br/>
                                We discovered many activities did not assist users meet their day-to-day goals or improve KPIs.  We quickly removed them, reframed them and revamped them.
                                <br/>
                                Using web tools, we measured the end-to-end time taken to successfully complete activities which provided clear quantitative results.
                            </p>
                            <img src={BSERP_10} className="img-fluid"/>
                            <p>Module dependency during runtime</p>
                            <h4>Delivery</h4>
                            <p>After 4 months of coding and bug fixing and writing over 100,000 lines of code, we rolled out the system as the primary tool. </p>
                            <h4>Post Launch Results</h4>
                            <p>Our investigations proved that users found the revised system both simpler to use, plus we observed a marked <b>reduction in execution time on average of 500%.</b>
                            <br/>
                                <br/>
                                More <b>complex tasks could be completed up to 105% faster</b> with the new usability measures.
                                <br/>
                                <br/>
                                These notable gains culminated in hours saved per week.
                                <br/>
                                Looking at the wider picture, we learned that making small measured improvements could significantly increase system engagement and contributed to wider service KPI goals.
                            </p>
                            <h4>Learning and Next Steps </h4>
                            <p>
                                Understanding the technology behind the backend was really helpful in making UX decisions. Several developers had refused to consider the problems due to developmental issues. This understanding of data modelling proved to be helpful in designs later on and empowered how complexity should be abstracted in the backend. Systems should be framed around users, not vice versa.
                                <br/>
                                <br/>
                                It was really inspiring to utilize feedback from the beta testers group before the launch in addition to the user surveys. It was a butterfly effect, the users were skeptical of all the feature promises at first, but when they saw it for themselves, they suggested additional ideas and opened up for change.
                                <br/>
                                <br/>
                                As time passes and users get comfortable, new feature requests and functionality bottlenecks start to surface. Now that the users have become familiar with the UI, The system is due for an UI revamp and addition of several other workflows as the business has evolved.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default Bserp