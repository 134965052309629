import {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import TIMESCAPE_BACKGROUND from "../assets/images/timescape/timescape-injection-change.png";
import TIMESCAPE_HERO from '../assets/images/thesis/image-asset.jpeg';
import TIMESCAPE_1 from '../assets/images/timescape/Subjective-Time.png';
import TIMESCAPE_2 from '../assets/images/timescape/timescape-injection-change.png';
import TIMESCAPE_3 from '../assets/images/timescape/timescape-injection-duration.png';
import TIMESCAPE_4 from '../assets/images/timescape/timescape-injection-order.png';
import TIMESCAPE_5 from '../assets/images/timescape/timescape-injection-PAST.png';
import TIMESCAPE_6 from '../assets/images/timescape/timescape-instruction.png';
import TIMESCAPE_7 from '../assets/images/timescape/Digital-Ethnography.png';
import TIMESCAPE_8 from '../assets/images/timescape/Concept-map.png';
import TIMESCAPE_9 from '../assets/images/timescape/Process-1.png';
import TIMESCAPE_10 from '../assets/images/timescape/Prototyping-1.png';
import TIMESCAPE_11 from '../assets/images/timescape/elasti-clock.png';
import TIMESCAPE_12 from '../assets/images/timescape/non-linear-clock.png';
import TIMESCAPE_13 from '../assets/images/timescape/Timescape-campaign-kit.png';
import TIMESCAPE_14 from '../assets/images/timescape/timescape-campaign-order.png';
import TIMESCAPE_15 from '../assets/images/timescape/timescape-campaign-past.png';
import TIMESCAPE_16 from '../assets/images/timescape/Timescape-campaign-elasticity.png';


import ImageGallery from "react-image-gallery";
import {ControlledCarousel} from "../components/ControlledCarousel";

const timescape_gallery_1 = [
        {
        original: TIMESCAPE_8,
        thumbnail: TIMESCAPE_8,
    },
    {
        original: TIMESCAPE_9,
        thumbnail: TIMESCAPE_9,
    },
    {
        original: TIMESCAPE_10,
        thumbnail: TIMESCAPE_10,
    },{
        original: TIMESCAPE_11,
        thumbnail: TIMESCAPE_11,
    },
    {
        original: TIMESCAPE_12,
        thumbnail: TIMESCAPE_12,
    }
];
const timescape_gallery_2 = [
    {
        original: TIMESCAPE_13,
        thumbnail: TIMESCAPE_13,
    },
    {
        original: TIMESCAPE_14,
        thumbnail: TIMESCAPE_14,
    },
    {
        original: TIMESCAPE_15,
        thumbnail: TIMESCAPE_15,
    },
    {
        original: TIMESCAPE_16,
        thumbnail: TIMESCAPE_16,
    }
];



const timescape_carousel_1 = [
    {
        url: TIMESCAPE_2,
        caption: 'The Change shot extrapolates the experience of pace in time. This protein formulation alters the neurons\' sodium channel properties to decrease the bandwidth of the information transferred in the synaptic dendrites. The restriction of information results in lower comparisons processed by the brain, giving a sensation of reduced change or acceleration.',
    },
    {
        url: TIMESCAPE_3,
        caption: 'The Duration shot extrapolates the elasticity of time. This protein formulation bypasses the common memory formation mechanism and grants control to the amygdala\'s secondary memory mechanisms that continuously register memory experiences, thereby giving a perception of a very long duration. The experience is similar to the ones that the brain experiences under fear, trauma, and longing.',
    },
    {
        url: TIMESCAPE_4,
        caption: 'The Order shot extrapolates the perception of the sequence created by the brain. This protein modulates the motor control synaptic mechanisms. It increases the usual delay of 100 ms in feedback between the brain\'s sensory and motor control mechanism to 1s. This creates a lag in perceived experiences, similar to the one of watching a poorly dubbed movie. The spatial-temporal distortion experienced after this shot is similar to the experience of Alzheimer\'s patients.',
    },
    {
        url: TIMESCAPE_5,
        caption: 'The Past shot extrapolates the experience of directionality in time. This protein formulation contains the enzyme HDAC2 that inhibits memory formation by condensing genes required to form new memories. This distorts the tensed memory attenuators, resulting in the recall of recently experienced things being perceived as long back. Higher dosages may lead to no relation of experience with linked events, leading to the perception of the past without the brain\'s time or imagination. The experience is similar to Deja Vu and some dreams.',
    }
];

class Timescape extends Component{
    render(){
        return(
          <div>
              <Container fluid className="g-0">
                  <Row className="hero-container">
                      <Col>
                          <img src={TIMESCAPE_BACKGROUND} className="img-fluid timescape-background"/>
                      </Col>
                  </Row>
              </Container>
              <Container>
                  <Row>
                      <Col lg={5}>
                          <h2>Bio-Hacking perceived linearity of time.</h2>
                      </Col>
                      <Col>
                          <p>Timescape is a speculative neural hacking kit that aims to deconstruct the notion of linearity in perceived time. It consists of injectable proteins that temporarily alter the brain's neural plasticity, which changes various perceptions of time—including sequence, direction, change, and duration.</p>
                      </Col>
                  </Row>
                  <Row>
                      <Col lg={10} className="ms-auto me-auto text-center hero-image">
                          <img src={TIMESCAPE_HERO} className="img-fluid"/>
                      </Col>
                  </Row>
                  <Row >
                      <Col lg={10} className="ms-auto me-auto text-center hero-quote">
                          <h4>“The speculative product is intended to provoke a conversation around the conflict between time as we experience it, and the construct of linearity that ‘clock&nbsp;time’&nbsp;imposes.”</h4>
                      </Col>
                  </Row>
                  <Row>
                      <Col lg={10} className="ms-auto me-auto">
                          <Row className="overviewContainer">
                              <Col>
                          <h3 className="text-muted">OVERVIEW</h3>
                          <hr></hr>
                          <Row>
                              <Col lg={6}>
                                  <h5>About</h5>
                                  <p>Timescape is built on one of the insights from an expert interview for my MFA thesis—an exploration into time poverty, colonialist origins of timekeeping, and ways to augment time that restore choice.</p>

                              </Col>
                              <Col lg={6}>
                                  <Row>
                                      <Col>
                                          <h5>Duration</h5>
                                          <p>2 Weeks</p>
                                      </Col>
                                      <Col >
                                          <h5>Category</h5>
                                          <p>Speculative Design
                                          </p>
                                      </Col>
                                  </Row>
                              </Col>
                          </Row>
                          <hr></hr>
                              </Col>
                          </Row>
                          <h4>Background</h4>
                          <p>The lockdown imposed by COVID-19 surfaced for many the conflict between clock time and perceived time. Days felt like months, months felt like days. Passage of time felt extremely elastic. In contrast, clock time is linear and repetitive. The perception of passage of time is dependent on the experiences we have. COVID-19 deprived us of all the multitude experiences we would have every day. In retrospect, we do not have any memories registered that indicate the passage of time in our brains. During the design research for my thesis, one of the expert interviewee, a Theoretical Physicist turned Neurologist highlighted that the <b>brain constructs a sense of time-based on the subjective experiences a person undergoes.</b> This project is an exploration of how might we deconstruct our notion of linearity of clock time and reframe it to experiential time, time that is non-directional, elastic, non-repetitive, and discrete.</p>
                          <img src={TIMESCAPE_1} className="img-fluid"/>
                          <h4>How it works</h4>
                          <p>Users can obtain the kit from their nearest pharmacy. The Timescape kit consists of four shots, each of which is responsible for changing a specific perception. Each syringe consists of a protein formulation that modulates the synaptic stimuli responsible for mechanisms responsible for creating the respective construct in the brain. Let’s take a look at the four different types here:</p>
                          <ControlledCarousel items={timescape_carousel_1} interval={2000} className="image-container"/>
                          <img src={TIMESCAPE_6} className="img-fluid"/>
                          <p>After administering the shot after, the proteins alter the neural synaptic connections for a brief moment. The video below showcases what happens before and after taking the shot.</p>
                          <Row>
                              <Col className="ms-auto me-auto">
                                  <div className="iframe-container">
                          <iframe className="responsive-iframe" title="vimeo-player" src="https://player.vimeo.com/video/505742303?"
                                width="768" height="435" frameBorder="0" allowFullScreen=""
                                  id="yui_3_17_2_1_1642883428488_77"></iframe>
                                  </div>
                              </Col>
                          </Row>
                          <h4>How it came together</h4>
                          <p>During the digital ethnographic research phase for his thesis, I came across many participants who expressed a conflict in experiential time and clock time during the COVID-19 pandemic.</p>
                          <img src={TIMESCAPE_7} className="img-fluid"/>
                          <p>I interviewed various subject matter experts in the fields of Psychology, Cognitive Neurology, Chronobiology, and Medicine to gain insights into experiential time. It was during one of these interviews when the interviewee referenced examples of Alzheimer's patients for spatial-temporal distortion, due to changes in synaptic endings, that I saw the possibility of a neural-hacking kit.<b> The key insight I had was that brain constructs a sense of time-based on the subjective experiences a person undergoes.</b>
                              <br></br>
                              <br></br>
                              I started concept mapping my learnings to my broader existing understanding of time, and started exploring ways to highlight the non-linearity of time by sketching and making different low fidelity prototypes.
                          </p>
                          <Row className="image-container">
                              <Col>
                                  <div >
                                      <ImageGallery items={timescape_gallery_1} showFullscreenButton={false} autoPlay={true} showPlayButton={false} showThumbnails={false}/>
                                  </div>
                              </Col>
                          </Row>
                          <h4 className="h4-below-caraousel">Campaigns</h4>
                          <p>To challenge the status quo of time that is external and independent to time that is subjective and experiential I created several billboard campaigns.</p>
                          <Row className="image-container">
                              <Col>
                                  <div >
                                      <ImageGallery items={timescape_gallery_2} showFullscreenButton={false} autoPlay={true} showPlayButton={false} showThumbnails={false} />
                                  </div>
                              </Col>
                          </Row>
                      </Col>
                  </Row>
              </Container>
          </div>
        );
    }
}

export default Timescape;