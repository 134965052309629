import {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Profile from "../assets/images/Karan.jpg";
import {ExternalLink} from "../components/ExternalLink";

function sendEmail(){
    window.open('mailto:karan.m.bansal@gmail.com','_blank');
}
function routeToLinkedIn(){
    window.open('https://www.linkedin.com/in/karan-bansal-68b2627a','_blank');
}
function routeToScheduling(){
    window.open('https://calendly.com/kbansal-2/hi','_blank');
}

class About extends Component{

    render(){
        return(

            <Container fluid className="g-0">
                <Row className="about-page">
                    <Col md={10} className="ms-auto me-auto">
                        <Row>
                            <Col md={6}>
                                <img src={Profile} className="img-fluid"/>
                            </Col>
                            <Col md={6}>
                                <p>Karan is a Design Strategist, Product Designer & Full Stack Web Developer based in NYC. He believes that design should lead to a humanizing influence and approaches each problem with that intent.
                                    <br></br>
                                    <br></br>
                                    Karan's interdisciplinary background in Design, Engineering, and Business, has imbibed in him a process that enables him to collaborate with multiple stakeholders, analyze complex inter-dependent systems, reframe hard-to-define problems, uncover actionable insights, and architect design interventions that navigate the constraints of multiple stakeholders to realize the required product and design vision.
                                    <br></br>
                                    <br></br>
                                    Karan is currently working as a Freelance Design Strategist and Product Designer. He graduated from the MFA <u><i><ExternalLink url="https://productsofdesign.sva.edu/" label="Products of Design" className="pointer"/></i></u> program at the School of Visual Arts, NYC, in 2021. His experience spans many industries and scales, in teams of all sizes and settings ranging from scrappy start-ups to resource-rich Fortune-500 companies. He has a background in engineering and started his career in 2014 as a Systems Engineer at Infosys Labs. He built analytic tools and dashboards utilizing Natural Language Processing and Distributed Computing for more than 50 Fortune 500 clients, including Citi Bank, Pepsi, Astra Zeneca, Telstra. In 2016 he went on to work as a Design Strategist and Web Developer in Bansal Scientific Equipments, a laboratory solutions provider to the Pharmaceutical and Manufacturing industry. His work focused on strategically driving revenue by using user-centered design to improve client-facing touch-points and augmenting stakeholder relationships with multiple clients like Pfizer, Johnson & Johnson.
                                    <br></br>
                                    <br></br>

                                </p>
                                <h5>Contact 📮 — karan.m.bansal@gmail.com</h5>
                                <h5>CV 📄 — <ExternalLink label="here" url="https://karanbansal-resume.s3.amazonaws.com/Karan_Bansal.pdf"/></h5>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>
        );
    }
}
export default About