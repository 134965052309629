import {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";

import {ExternalLink} from "../components/ExternalLink";
import lp_hero_1 from "../assets/images/lockdown-playbook/1_1_1.jpg";
import lp_hero_2 from "../assets/images/lockdown-playbook/3_2_4.jpg";
import ColoringBook from "../components/ColoringBook";
import {lp_hero_1_svg,lp_hero_2_svg} from "../assets/images/lockdown-playbook/svgs";
import lp_1 from "../assets/images/lockdown-playbook/Lockdown-screenshot-keep-your-distance.jpg";
import lp_2 from "../assets/images/lockdown-playbook/css-animation.gif";
import lp_3 from "../assets/images/lockdown-playbook/Lockdown-Screnshot-Renew-Recipe.jpg";
import lp_4 from "../assets/images/lockdown-playbook/EAT_Renew-a-family-recipe.jpeg";
import lp_5 from "../assets/images/lockdown-playbook/Image-of-woman-in-mask-with-illustrations.jpeg";
import lp_6 from "../assets/images/lockdown-playbook/screenshot-of-website-with-Lockdown-products.jpeg";
import lp_7 from "../assets/images/lockdown-playbook/Lockdown-Screnshot-Exercise.jpg";
import lp_8 from "../assets/images/lockdown-playbook/Lockdown-screenshot-move-as-one.jpg";
import lp_9 from "../assets/images/lockdown-playbook/Screenshot-of-Lockdownplaybook-website-1.jpeg";
import lp_10 from "../assets/images/lockdown-playbook/Screenshot-of-Lockdownplaybook-website-clean-the-house.jpeg";
import lp_11 from "../assets/images/lockdown-playbook/Lockdown-screenshot-best-sleep.jpg";
import lp_12 from "../assets/images/lockdown-playbook/Screenshot-of-Lockdownplaybook-website-Keep-your-distance-1.jpeg";
import lp_13 from "../assets/images/lockdown-playbook/Lockdown-screenshot-marry-best-friend-1.jpg";
import lp_14 from "../assets/images/lockdown-playbook/Lockdown-screenshot-kick-stress-out-1.jpg";
import lp_15 from "../assets/images/lockdown-playbook/Screenshot-of-Lockdownplaybook-website-move-as-one-1.jpeg";
import lp_16 from "../assets/images/lockdown-playbook/Eat-renew-browser.jpeg";
import LP_BACKGROUND from "../assets/images/lockdown-playbook/LP-Background.png";



class LockdownPlaybook extends Component{

    render(){
        return(
            <div>
                <Container fluid className="g-0">
                    <Row className="hero-container">
                        <Col>
                            <img src={LP_BACKGROUND} className="img-fluid lockdownPlaybook-Background"/>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col lg={5}>
                            <h2>Enlivening everyday rituals during the pandemic.</h2>
                        </Col>
                        <Col>
                            <p><u><i><ExternalLink url="https://www.thelockdownplaybook.com/" label="The Lockdown Playbook " className="pointer"/></i></u> is a set of strategies and activities for people locked down in the COVID-19 pandemic that give them hope, and help them triumph over the everyday challenges of the quarantine. Embracing hope and resisting despair in the challenging times , the playbook comprises of activities that bring imagination and ingenuity into peoples daily rituals. The Playbook is anchored around a central website along with a downloadable coloring book. The activities take the form of storyboards, are broken down into 5 categories: EAT, PROTECT, SOCIALIZE, REST, AND EXERCISE, and within each section are multiple stories.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={10} className="ms-auto me-auto">
                            <Row className="my-md-4-5">
                                <Col className="overlay-container">
                                    <div className="img-overlay-wrap">
                                        <img src={lp_hero_1}/>
                                        {lp_hero_1_svg}
                                    </div>
                                </Col>
                                <Col>
                                    <div className="img-overlay-wrap">
                                        <img src={lp_hero_2}/>
                                        {lp_hero_2_svg}
                                    </div>
                                </Col>
                            </Row>
                            <Row >
                                <Col lg={10} className="ms-auto me-auto text-center hero-quote">
                                    <h4>The mission was to spark physical behaviors that— we hope!— would change the sense of play and wellbeing of individuals safe at home&nbsp;in&nbsp;quarantine.</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={10} className="ms-auto me-auto">
                                    <Row className="overviewContainer">
                                        <Col>
                                    <h3 className="text-muted">Overview</h3>
                                    <hr></hr>
                                    <Row>
                                        <Col lg={7}>
                                            <h5>About</h5>
                                            <p>This project is the result of the Design Performance class at SVA’s MFA in Products of Design program. Design Performance is an experience and exhibition design class, exploring how designed objects impact physical behavior, and how those behaviors impact our roles in society.   The work of Design Performance is annually showcased in a highly-physical, interactive exhibition at Wanted Design Manhattan during the NYCxDESIGN festival. Because of Covid-19, our coursework—and exhibition—like so much else of global culture, was forced to move online. Nonetheless, we attempted to use design to continue the mission of the course.</p>
                                        </Col>
                                        <Col >
                                            <Row>
                                                <Col>
                                                    <h5>Duration</h5>
                                                    <p>7 Weeks, Mar - Apr 2020</p>
                                                </Col>
                                                <Col>
                                                <h5>Tools</h5>
                                                    <p>HTML, JS, CSS</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h5>Collaborators</h5>
                                                    <p><i><u><ExternalLink url="https://productsofdesign.sva.edu/class-of-2021" label="SVA PoD Class of 2021" className="pointer"/></u></i></p>
                                                </Col>
                                                <Col>
                                                    <h5>Responsibilities</h5>
                                                    <p>UX/UI , Web Development</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr/>
                                        </Col>
                                    </Row>
                                    <img src={lp_1} className="img-fluid"/>
                                    <h3 className="text-muted">PROCESS</h3>
                                    <p>The project started out with the theme of HOPE. How might we design objects that implored physical behaviors and brought people hope. The theme deliverables were meant to be various objects and an exhibition space at Wanted Design that brought Hope to people.  Midway due to COVID-19 of course, Wanted Design (along with all of the NYCxDESIGN celebration) was cancelled, and quarantined at home, we researched and contemplate what “design performances” we could inspire within the constraints of the pandemic. We identified several rituals affected by the pandemic and decided to focus on the rituals surrounding EAT, PROTECT, SOCIALIZE, REST, AND EXERCISE. We put together a selection of physical, mental, and emotional strategies and activities that would help us triumph over the untold trials we face during the time and that would help us emerge from the period as more mindful, more purposeful, and more jovial versions of our previous selves. The team collectively finalized to create a series of instructions in the form of storyboards, that the user could interact with and engage physically. The storyboards also doubled as coloring book, that would allow people in isolation to have some fun. Everyone’s challenges are different, of course. But in the illustrations, we chose to enliven some everyday activities—adding a dash of design and a hint of promise. </p>
                                    <h4>Initial Proposals & Failed Attempts</h4>
                                    <p>In parallel to the downloadable coloring book, I started exploring ways in which people could engage with the content digitally. One of the proposals I came up with was to have the website as a digital coloring book. Where in the users could navigate the website by coloring in the illustrations using their mouse. </p>
                                    <p className="note">Hover over any part of the image below to color the section.</p>
                                    <Row className="image-container">
                                        <Col lg={7} className="ms-auto me-auto">
                                            <ColoringBook/>
                                        </Col>
                                    </Row>
                                    <p>Although it seemed fun and the idea of having a digital plus a physical coloring book was more coherent, the exploration quickly had to be scrapped. <b>The technical constraints hindered in the way of providing an intuitive and consistent interaction experience across all the illustrations.</b> The illustrations were drawn in Adobe Illustrator, and the way the attributes and paths in the SVG’s produced were dependent on how the actual illustrations were done by our team. There were over 70 illustrations done, each had a different set of geometries, some of them were closed loops, some were not and the entire process started getting complex programmatically. To achieve the goal, each illustration would have had to follow a very strict rule of the way things were drawn. And given the number of the illustrations, the different styles of illustration and getting it same across the various illustrators on our team, pushing this feature was complex. </p>
                                    <h4>Evolved animation</h4>
                                    <p>Given the constraints of having a static deployment that could be hosted without any future maintenance, and the time frame, we settled on the deliverable as a static website with subtle animations that evoked a sense of drawing.</p>
                                   <Row className="image-container">
                                       <Col className="text-center">
                                           <img src={lp_2} className="img-fluid"/>
                                       </Col>
                                   </Row>

                                    <h3 className="text-muted">OUTCOME</h3>
                                    <p>The website launched with 70 beautiful illustrations accompanied with a downloadable PDF Coloring Book, where families could download all 70 illustrations, color them in, and then share them on social media. </p>
                                    <Row className="image-container">
                                        <Col lg={6}>
                                            <img src={lp_3} className="img-fluid my-md-0"/>
                                        </Col>
                                        <Col lg={6}>
                                            <img src={lp_4} className="img-fluid my-md-0"/>
                                        </Col>
                                    </Row>
                                    <p className="mb-md-0">View the whole coloring book by clicking on the <ExternalLink label="ISSUU link" url="https://issuu.com/productsofdesign/docs/sva_lockdown_playbook_ver._1.0"/> below:</p>
                                    <Row>
                                        <Col className="ms-auto me-auto">
                                            <div className="iframe-container">
                                                <iframe className="responsive-iframe" scrolling="no" frameBorder="0" id="player"
                                                        src="//e.issuu.com/embed.html#0/78788320?referrer=https%3A%2F%2Fwww.karanbansal.me%2F"
                                                        allowFullScreen="true"></iframe>
                                            </div>
                                        </Col>
                                    </Row>
                                    <p>In addition, the illustrations have been applied to products that visitors can order, where all proceeds are donated to <u><ExternalLink label="WHO COVID-19 Solidarity Response Fund." url="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/donate" /> </u></p>
                                    <Row className="image-container">
                                        <Col>
                                            <img src={lp_5} className="img-fluid my-md-0"/>
                                            <img src={lp_6} className="img-fluid my-md-0"/>
                                        </Col>
                                    </Row>
                                    <p>Check out more of the project below!</p>
                                    <img src={lp_7} className="img-fluid"/>
                                    <Row className="image-container">
                                        <Col>
                                            <img src={lp_16} className="img-fluid my-md-0"/>
                                        </Col>
                                        <Col>
                                            <img src={lp_8} className="img-fluid my-md-0"/>
                                        </Col>
                                    </Row>
                                    <img src={lp_9} className="img-fluid"/>
                                    <Row className="image-container">
                                        <Col>
                                            <img src={lp_10} className="img-fluid my-md-0"/>
                                        </Col>
                                        <Col>
                                            <img src={lp_11} className="img-fluid my-md-0"/>
                                        </Col>
                                    </Row>
                                    <Row className="image-container">
                                        <Col>
                                            <img src={lp_12} className="img-fluid my-md-0"/>
                                            <img src={lp_13} className="img-fluid my-md-0"/>
                                            <img src={lp_14} className="img-fluid my-md-0"/>
                                            <img src={lp_15} className="img-fluid my-md-0"/>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default LockdownPlaybook