import {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import ClGrid from "../components/ClGrid";
import {ExternalLink} from "../components/ExternalLink";

import ORBT_ANIMATION from "../assets/images/orbt/Orbt-Background.gif";
import ORBT_HERO from "../assets/images/orbt/Orbit-Hero.png";
import ORBT_1 from "../assets/images/orbt/Context-shot-4.png";
import ORBT_2 from "../assets/images/orbt/design-process.png";
import ORBT_3 from "../assets/images/orbt/facts.png";
import ORBT_4 from "../assets/images/orbt/Pen-Deconstructed.png";
import ORBT_5 from "../assets/images/orbt/history.png";
import ORBT_6 from "../assets/images/orbt/modern-history.png";
import ORBT_7 from "../assets/images/orbt/Employees-in-an-open-shared-office-space.jpg";
import ORBT_8 from "../assets/images/orbt/Digital-Office-Virtual-Workplace-4525.jpeg";
import ORBT_9 from "../assets/images/orbt/different-users.png";
import ORBT_10 from "../assets/images/orbt/design-opportunity.png";
import ORBT_11 from "../assets/images/orbt/typology.png";
import ORBT_12 from "../assets/images/orbt/portability.png";
import ORBT_13 from "../assets/images/orbt/anatomy.png";
import ORBT_14 from "../assets/images/orbt/sketch-2.png";
import ORBT_15 from "../assets/images/orbt/sketch-3.png";
import ORBT_16 from "../assets/images/orbt/sketch-1.png";
import ORBT_17 from "../assets/images/orbt/cad-evolution.jpg";
import ORBT_18 from "../assets/images/orbt/protoype-2.png";
import ORBT_19 from "../assets/images/orbt/cmf.png";
import ORBT_20 from "../assets/images/orbt/stand-pen.jpg";
import ORBT_21 from "../assets/images/orbt/features.png";
import ORBT_22 from "../assets/images/orbt/circular-economy.png";
import ORBT_23 from "../assets/images/orbt/environmental-impact.jpg";
import ORBT_24 from "../assets/images/orbt/branding.png";
import ORBT_25 from "../assets/images/orbt/Green.png";
import ORBT_26 from "../assets/images/orbt/Orange.png";
import ORBT_27 from "../assets/images/orbt/Blue.png";
import ORBT_28 from "../assets/images/orbt/Purple.png";
import ORBT_29 from "../assets/images/orbt/packaging-2.png";
import ORBT_30 from "../assets/images/orbt/packaging.png";
import ORBT_31 from "../assets/images/orbt/customizations.png";
import ORBT_32 from "../assets/images/orbt/tradeshoworbt-portfolio-resorces-20.png";
import ORBT_33 from "../assets/images/orbt/common-areas.png";
import ORBT_34 from "../assets/images/orbt/frontdeskorbt-portfolio-resorces-21.png";

const orbt_grid_1 = [
    {
        original: ORBT_7,
        thumbnail: ORBT_7,
    },
    {
        original: ORBT_8,
        thumbnail: ORBT_8,
    }
];

const orbt_grid_2 = [
    {
        original: ORBT_11,
        thumbnail: ORBT_11,
    },
    {
        original: ORBT_12,
        thumbnail: ORBT_12,
    }
];
const orbt_grid_3 = [
    {
        original: ORBT_14,
        thumbnail: ORBT_14,
    },
    {
        original: ORBT_15,
        thumbnail: ORBT_15,
    }
];
const orbt_grid_4 = [
    {
        original: ORBT_17,
        thumbnail: ORBT_17,
    },
    {
        original: ORBT_18,
        thumbnail: ORBT_18,
    }
];
const orbt_grid_5 = [
    {
        original: ORBT_25,
        thumbnail: ORBT_25,
    },
    {
        original: ORBT_26,
        thumbnail: ORBT_26,
    },
    {
        original: ORBT_27,
        thumbnail: ORBT_27,
    },
    {
        original: ORBT_28,
        thumbnail: ORBT_28,
    }
];
class Orbt extends Component{
    render(){
        return(
          <div>
              <Container fluid className="g-0">
                  <Row className="hero-container">
                      <Col>
                          <img src={ORBT_ANIMATION} className="img-fluid orbt_background"/>
                      </Col>
                  </Row>
              </Container>
              <Container>
                  <Row>
                      <Col lg={5}>
                          <h2>Designing a circular economy for disposable ballpoint pens.</h2>
                      </Col>
                      <Col>
                          <p>Orbt is the first low waste and environmentally low impact set of ballpoint pens, designed primarily for organizations rather than individuals. Inspired by the satellites revolving in the Earth’s geostationary orbit, Orbt is designed to, quite literally, stay around the user for years.
                            <br/>
                              <br/>
                              The iconic Reuleaux Triangle shape of the body and its magnetic stand’s matching profile combined with its non-portable affordances nudge the user to place the pen back in its home. Promoting reusability and preventing misplacement, use after use. Coupled with the pens’ materiality and utilization of a circular business model by offering a recycling program for organizations, Orbt is able to minimize the environmental impact of ballpoint pens in every stage of the product lifecycle. </p>
                      </Col>
                  </Row>
                  <Row>
                      <Col lg={10} className="ms-auto me-auto text-center hero-image">
                          <img src={ORBT_HERO} className="img-fluid"/>
                      </Col>
                  </Row>
                  <Row>
                      <Col lg={10} className="ms-auto me-auto text-center hero-quote">
                          <h4>“Our mission is to make writing instruments that are as beautiful and elegant as the ideas they help express. At the same time, we aim to create a product lifecycle that is mindful of both the consumer experience and&nbsp;its&nbsp;environmental&nbsp;impact.”</h4>
                      </Col>
                  </Row>
                  <Row>
                      <Col lg={10} className="ms-auto me-auto">
                          <Row className="overviewContainer">
                              <Col>
                          <h3 className="text-muted">OVERVIEW</h3>
                          <hr></hr>
                          <Row>
                              <Col lg={6}>
                                  <h5 >The Brief</h5>
                                  <p>As part of the Product Branding and Experience course at MFA Products of Design, School of Visual Arts taught by Hlynur Atlason, we had to identify particularly wasteful products—either due to scale or frequency of use—and reimagine how a different product could fulfill that need more intentionally.</p>
                              </Col>
                              <Col lg={6}>
                                <Row>
                                    <Col lg={3}>
                                        <h5 >Duration</h5>
                                        <p>10 Weeks</p>
                                    </Col>
                                    <Col lg={5}>
                                        <h5 >Responsibilities</h5>
                                        <p>Problem Definition
                                            <br></br>
                                            User Research
                                            <br></br>
                                            Product Strategy
                                            <br></br>
                                            Product Design
                                            <br></br>
                                            Brand Strategy
                                        </p>
                                    </Col>
                                    <Col lg={4}>
                                        <h5 >Collaborators</h5>
                                        <p>Ching Yi Lo
                                            <br></br>
                                            Yufeng Peng
                                        </p>
                                    </Col>
                                </Row>
                              </Col>
                          </Row>
                          <Row>
                              <Col>
                                  <hr></hr>
                                  <h5>The Problem</h5>
                                  <p>Currently, ballpoint pens are ubiquitous and inconspicuous. People borrow them, steal them, loan them, and lose them. They never give them a second thought. The different materials in pen components(cap, ink cartridge, spring, barrel, etc.), their construction and size make it impossible for them to be recycled. Each year approximately 1.6 billion pens are sent to the landfill—and that’s in the United States alone.  How can we avert the careless behavior towards pens without hurting our convenience while promoting waste reduction and sustainability?</p>
                              </Col>
                              <Col>
                                  <hr></hr>
                                  <h5>The Opportunity</h5>
                                  <p>To create a writing instrument that communicates a clear sense of ownership and offers the best writing experience so that pens become more personal and less likely to end up in trash. </p>
                              </Col>
                          </Row>
                              </Col>
                          </Row>
                          <img src={ORBT_1} className="img-fluid"/>
                          <h3 className="text-muted">PROCESS</h3>
                          <p>Orbt was a result of multiple iterative rounds of research and problem reframing. The chart below shows the outline of our process. We started by conducting the primary research, which consisted of looking at the historical trajectory of the product to trace key events that influenced the current form and function. We scanned the current market offerings and existing typologies and did a comparative analysis of their benefits and positioning.  We then looked for quantitative and qualitative market data to understand the various factors affecting the problem and started scoping down  the problem for our focus area. On narrowing our area down, we studied user flows to gain insights into point of entries and user behavior. After several brainstorm and problem reframe sessions, we identified the design opportunity and created a brief that would allow us to base our design philosophy and create rapid sketches and ideas. We drafted a Vision that would allow us to frame the narrative of the product, branding and communication for our deliverables. We then held group reviews for feedback from users and iterated on refining the brief. We then proceeded with creating 3D models and exploring considerations for Color, Material & Finish (CMF) and simultaneously worked on creating the Brand Guidelines and Visual Identity based on our vision. We reached out to users to receive feedback on the iterations and refined our models and design based on the feedback. Simultaneously, we conducted various Environmental Impact assessments on our product lifecycle to refine our offering. The final resulted in a Pitch Presentation of the case study.</p>
                          <img src={ORBT_2} className="img-fluid"/>
                          <h4>The facts that started it all</h4>
                          <p>Our primary research indicated the scale and impact of the disposable nature of the everyday pen. Even if we assumed ideal human behavior towards the writing instruments and 100% recyclable materials, the pens mere construction, size and complexity made it impossible for them to be recycled according to the Association of Plastic Recyclers. </p>
                          <Row className="image-container">
                              <Col className="text-center">
                                  <img src={ORBT_3} className="img-fluid"/>
                                  <img src={ORBT_4} className="img-fluid"/>
                              </Col>
                          </Row>

                          <h4>Rewinding History</h4>
                          <p>To understand the context of how we arrived at this state, we looked at the historical influences and development of pens. Historically pens held an emotional and sentimental value, but with the introduction of the Ballpoint pen, we noticed a trend towards practicality and mobilization. The introduction of BIC’s Cristal with the goal for making the pen accessible to the masses and introduction of the disposable plastic pen had reduced the value of writing instruments to a functional and utilitarian one. Since then, the industry has been further spiraling the value by increasing mass manufacturing and offering bulk packs. </p>
                          <img src={ORBT_5} className="img-fluid"/>
                          <img src={ORBT_6} className="img-fluid"/>
                          <h4>Zooming Out on the User</h4>
                          <p>We spoke to over 20 subject matter experts, including pen collectors, brand founders, bloggers, heads of existing pen companies, stationary sellers, office resource managers and users. We were surprised to find that despite the increasing penetration of digital devices in our lives, and the reduction of paperwork requiring pens, the sales of pens had been consistently increasing. Thanks to the growing educational sector. The pen’s design has largely remain unchanged since the 1950’s, despite the drastic change in the use cases. This made sense in the Educational sector, given the fact that the mode of operation is primarily paper based. However, the story in the office workplaces was completely different. </p>

                          <h5>We found a reduced sentiment for pens amongst office workers, due to the increase in digital devices as their primary work mode, and due to the thinning boundary between personal and shared resources because of the increase in shared spaces in working environments. </h5>
                          <Row className="clGridContainer">
                              <ClGrid images={orbt_grid_1} cols={2} aspectRatio="16x9" />
                          </Row>
                          <p>After evaluating the scope of impact, we decided to scope down our project to users within the workplace. During our online research and interviews, we found some surprising figures.</p>
                            <Row className="margin-top-2">
                                <Col>
                                    <h2>4.3</h2>
                                    <p>Avg no. of required pens in an year per person</p>
                                </Col>
                                <Col lg={2}>
                                    <h2>28</h2>
                                    <p>Avg pens in a household</p>
                                </Col>
                                <Col>
                                    <h2>56%</h2>
                                    <p>People own a pen received through sales promotion</p>
                                </Col>
                                <Col>
                                    <h2>4/10</h2>
                                    <p>Americans buying pen for themselves</p>
                                </Col>
                                <Col>
                                    <h2>81%</h2>
                                    <p>People did not have a preference of the tip</p>
                                </Col>
                                <ExternalLink url="https://analysis.technavio.com/pens-market-analysis" label="Source" ></ExternalLink>
                            </Row>
                          <h5>Fewer people were buying pen for themselves in an office environment.</h5>
                          <p>Which led us to shift our target user group from individual employees to organizations that were buying pens for them. This allowed us to reconsider our value proposition and opened a way for a systemic entry point in the problem.</p>
                          <img src={ORBT_9} className="img-fluid"/>

                          <h4>The Key Insight that inspired orbt</h4>
                          <h5>There is no clear sense of ownership of a disposable ballpoint pen.</h5>
                          <p>Lack of ownership resulted in the no frills behavior of the user. There were multiple reasons why a user did not assume ownership to a pen. Chief among them are because pens are cheap looking, they have an impersonal point of entry, they are a shared resource, they are ubiquitous in shared spaces, they are easy to misplace since they are portable, and that they are expendable in nature. </p>

                          <h4>Design Opportunity</h4>
                          <h5>By designing to inform a clear sense of ownership and offering the best writing experience, pens will inherently be less likely to end up in trash. </h5>
                          <p>By addressing the reasons that prohibited ownership through the design, we were able to articulate a design direction and list of product features that would assist our goals. </p>
                          <img src={ORBT_10} className="img-fluid"/>
                          <p>It was surprising to us that during our market offering scans of pens across all spectrums, we found broadly 2 typologies, Pens with Caps, and pen with Retracting Mechanisms. Both of these typologies were created around the idea of providing portability and ergonomics. And they were a slightly conflicting goal, given the anatomy of the hand. We broke down the anatomy of a pen to better understand its different features and intents.  </p>
                          <Row className="clGridContainer">
                              <ClGrid images={orbt_grid_2} cols={2} aspectRatio="16x9" />
                          </Row>
                          <img src={ORBT_13} className="img-fluid"/>
                          <h4>Crafting the Vision</h4>
                          <p>Based on our problem set, target audience and insights we started forming a narrative through the Vision, Mission and Values. These would guide our ideation and brainstorming sessions ultimately keep our design and branding coherent.</p>
                          <h5>Belief</h5>
                          <p>We believe that pens should fill imagination and hearts not landfills.
                            <br></br>
                              We see pens as tools that help us express our emotions, imaginations and curiosity, the very things that define us and make us human. The things that nourish our condition, and we believe that nourishment should never be exploitative. </p>
                          <h5>Mission</h5>
                          <p>Our mission is to make writing instruments that are as beautiful and elegant as the ideas they help express while having an infinitesimal impact on the environment.</p>

                          <h4>Ideation</h4>
                          <p>With all the insights and briefs from our research phase, we began ideating in this direction with the intent of defining ownership. We explored what ownership meant, the different types of ownerships, and the difference between owning and using. With our target user as organizations, we progressed towards ideas that defined positional ownership.</p>
                          <Row className="clGridContainer">
                              <ClGrid images={orbt_grid_3} cols={2} aspectRatio="16x9" />
                          </Row>
                          <img src={ORBT_16} className="img-fluid"/>

                          <h4>Product Development</h4>
                          <p>We started modeling some of the sketches in Shapr3D to check for some manufacturing considerations we may have not considered. And with the models we progressed towards material considerations considering the product lifecycle and manufacturing ability. We finalized the product features that enabled optimum writing experience while serving our goal. </p>
                          <h5>3D Model</h5>
                          <Row className="clGridContainer">
                              <ClGrid images={orbt_grid_4} cols={2} aspectRatio="16x9" />
                          </Row>
                          <h5>CMF</h5>
                          <Row className="image-container">
                              <Col>
                                  <img src={ORBT_19} className="img-fluid"/>
                                  <img src={ORBT_20} className="img-fluid"/>
                              </Col>
                          </Row>
                          <h4>Features</h4>
                          <img src={ORBT_21} className="img-fluid"/>
                          <h4>Proposed Circular Model</h4>
                          <img src={ORBT_22} className="img-fluid"/>
                          <h4>Environmental Impact</h4>
                          <img src={ORBT_23} className="img-fluid"/>
                          <h3 className="text-muted">FINAL ASSETS</h3>
                          <p>Based on our values we created an identity system that could be used for Surfaces as small as pens and as big as trade show billboards. </p>
                          <img src={ORBT_24} className="img-fluid"/>
                          <h4>Product</h4>
                          <img src={ORBT_HERO} className="img-fluid"/>
                          <Row className="clGridContainer">
                              <ClGrid images={orbt_grid_5} cols={2} aspectRatio="16x9" />
                          </Row>
                          <h4>Packaging</h4>
                          <img src={ORBT_29} className="img-fluid"/>
                          <img src={ORBT_30} className="img-fluid"/>
                          <h4>Customizations for Buyers</h4>
                          <img src={ORBT_31} className="img-fluid"/>
                          <h4>Campaigns</h4>
                          <p>We created a series of campaigns targeted towards corporate buyers. To create a reinforce organizational-cum-personal ownership, we also targeted campaigns in shared office spaces. </p>
                          <img src={ORBT_32} className="img-fluid"/>
                          <img src={ORBT_33} className="img-fluid"/>
                          <img src={ORBT_34} className="img-fluid"/>
                      </Col>
                  </Row>
              </Container>
          </div>
        );
    }
}

export default Orbt;