import {Component, useState} from "react";
import {Carousel, Col, Modal, Row} from "react-bootstrap";
import THESIS_HERO from "../assets/images/thesis/thesis_hero.jpeg";
import CarouselComponent from "./CarouselComponent";
import ImageGallery from "react-image-gallery";

class ClGrid extends Component{
constructor(props) {
    super();
    this.state={show:false,selectedIndex:0};
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSelect= this.handleSelect.bind(this);
}

handleSelect(i) {
    this.setState({
        selectedIndex: i
    });
}

handleClose(){
    this.setState({
        show:false
    });
}

handleShow(i){
    this.handleSelect(i);
    this.setState({
        show:true
    });
}

    render(){
        const images = this.props.images;
        const cols = this.props.cols;
        let rows;
        if(this.props.aspectRatio==='1x1'){
            rows = images.map((image,index)=>
                <Col key={index} lg={12/cols} onClick={(e)=> this.handleShow(index)}>
                    <div className="ar-1x1" >
                    <img src={image.original}/>
                    </div>
                </Col>);
        }else if(this.props.aspectRatio==='3x2'){
            rows = images.map((image,index)=>
            <Col key={index} lg={12/cols} onClick={(e)=> this.handleShow(index)}>
                <div className="ar-3x2" >
                    <img src={image.original}/>
                </div>
            </Col>);
        }
        else if(this.props.aspectRatio==='16x9'){
            rows = images.map((image,index)=>
                <Col key={index} lg={12/cols} onClick={(e)=> this.handleShow(index)}>
                    <div className="ar-16x9" >
                        <img src={image.original}/>
                    </div>
                </Col>);
        }
        else {
            rows = images.map((image,index)=>
                <Col key={index} lg={12/cols} onClick={(e)=> this.handleShow(index)}>
                    <div>
                    <img src={image.original} className="img-fluid"/>
                    </div>
                </Col>);
        }


       return(
           <div>
           <Row id="clGallery">
               {rows}
           </Row>
           <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
               <Modal.Header closeButton></Modal.Header>
               <Modal.Body>
                   <ImageGallery items={this.props.images} startIndex={this.state.selectedIndex} showFullscreenButton={false}/>
               </Modal.Body>
           </Modal>
           </div>
       );
    }
}

export default ClGrid