import {Component} from "react";
import {Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import {ExternalLink} from "./ExternalLink";
import {Link} from "react-router-dom";

function sendEmail(){
    window.open('mailto:karan.m.bansal@gmail.com','_blank');
}
function routeToLinkedIn(){
    window.open('https://www.linkedin.com/in/karan-bansal-68b2627a','_blank');
}
function routeToScheduling(){
    window.open('https://calendly.com/karan-m-bansal/30min','_blank');
}

class Footer extends Component{

    render(){
        let envelope = () => <i className="bi bi-envelope"></i>;
        let date = new Date();
        let currentYear = date.getFullYear();

        return(
            <Container fluid className="footer">
                <Row>
                    <Col md={10} className="ms-auto me-auto">
                        <hr></hr>
                        <Row>
                            {/*<Col >*/}
                            {/*    <h6 className="footer-font-size">Karan Bansal</h6>*/}
                            {/*    <h6 className="footer-font-size">New York City, NY</h6>*/}
                            {/*</Col>*/}
                            <Col md={6} >
                                <h6 className="footer-font-size">© Copyright {currentYear}. All Rights Reserved.</h6>
                            </Col>
                            <Col className="text-end">
                                {/*<h6 className="footer-font-size">Say Hello</h6>*/}
                                <h6 className="footer-font-size">Say Hello: &nbsp;&nbsp;&nbsp;<i onClick={sendEmail} className="bi bi-envelope"></i>&nbsp;&nbsp;&nbsp;<i onClick={routeToLinkedIn} className="bi bi-linkedin"></i>&nbsp;&nbsp;&nbsp;<i onClick={routeToScheduling} className="bi bi-calendar-date"></i></h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}
export default Footer