import {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import NIA_BACKGROUND from "../assets/images/nia/nia-background.png";
import NIA_1 from "../assets/images/nia/pma-dashboard.png";
import NIA_2 from "../assets/images/nia/pareto.png";
import NIA_3 from "../assets/images/nia/correlation.png";
import NIA_4 from "../assets/images/nia/workflow.png";

class Nia extends Component{

    render(){
        return(
            <div>
                <Container fluid className="g-0">
                    <Row className="hero-container">
                        <Col>
                            <img src={NIA_BACKGROUND} className="img-fluid nia-background"/>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col lg={5}>
                            <h2>Building resiliency in IT operations through an AI&nbsp;platform.</h2>
                        </Col>
                        <Col>
                            <p>Nia AIOps brings the power of AI and automation to IT operations leveraging capabilities like Machine Learning, Natural Language Processing and Computer Vision. It employs powerful AI models for ticket analysis, enrichment, pattern detection and anomaly detection. Nia AIOps enables IT Organizations to move towards predictive maintenance and autonomous operations with its state-of-the art anomaly detection and self-heal orchestration capabilities.</p>
                        </Col>
                    </Row>
                    <Row className="hero-image">
                        <Col lg={10} className="ms-auto me-auto text-center">
                            <div className="iframe-container">
                                <iframe className="responsive-iframe" width="1004" height="564" src="https://www.youtube.com/embed/JwWo3p3Bg9A"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={10} className="ms-auto me-auto text-center hero-quote">
                            <h4>Nia AIOps helps improve service, identify inefficiencies in the system, analyze problem areas, detect anomalies, prevent outages and orchestrate&nbsp;remediation.</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={10} className="ms-auto me-auto">
                            <Row className="overviewContainer">
                                <Col>
                            <h3 className="text-muted">Overview</h3>
                            <hr></hr>
                            <h5 className="note">Note: To meet non-disclosure agreement, information is obfuscated. Please feel free to reach out to me to discuss this project.</h5>
                            <Row>
                                <Col lg={6}>
                                    <h5>About</h5>
                                    <p>Nia AIOps is a comprehensive set of AI and automation capabilities to accelerate IT Service Management transformations and deliver measurable business impact. I was working on this project during my employment period at Infosys Ltd as a Systems Engineer. I was part of a 5 member team that was responsible for creating the Discovery phase of the platform. We built a Problem Management Analytics tool that analyzed patterns in existing ticket data using Natural Language Processing and helped more than 150 clients across diverse industries identify opportunities for automation. As a Full Stack Engineer, I was responsible for implementing proprietary NLP models in the tool, creating several dashboards that allowed the clients to analyze their data and scaling the tool to support Big Data and Distributed computing. </p>
                                </Col>
                                <Col lg={6}>
                                <Row>
                                    <Col>
                                        <h5>Duration</h5>
                                        <p>Oct 2014 - Mar 2016</p>

                                        <h5>Client Types</h5>
                                        <p>Banking & Financial services, Communications, Electronics, Retail, Supply Chain and Logistics. </p>
                                    </Col>
                                    <Col>
                                        <h5>Role</h5>
                                        <p>Systems Engineer</p>

                                        <h5>Tools & Frameworks</h5>
                                        <p><b>Frontend:</b> Angular, D3.js, Tableau</p>

                                        <p><b>Backend:</b> Java, Spring MVC, Apache Spark, Apache Lucene, Hadoop, MSSQL, Elasticsearch</p>
                                    </Col>
                                </Row>
                                </Col>
                                <hr/>
                            </Row>
                                </Col>
                            </Row>
                            <h4>Analytics for Instant Answers</h4>
                            <p><b>Problem Management Analytics</b> (PMA) solutions are key to data analytics and critical for the overall support processes, helping drive efficiency with a <b>data-driven approach</b> to analyze incident data, reducing the manual retrospective analysis and increasing productivity. Problem Management Analytics help in the following ways:</p>

                                <ul>
                                <li>Increasing productivity by making day to day operations more effective and efficient</li>
                                <li>Improving business interface and communicating the health of the support services on a periodic basis</li>
                                <li>Reducing risk by ensuring root cause analysis of incidents is carried out, fixed and documented within stipulated time</li>
                            </ul>
                                <p>
                                The PMA tool helps problem management teams analyze incident data – both structured and unstructured, and provide <b>instant answers to questions.</b>
                                <br></br>
                                We used patented NLP models to extract key information from clients tickets and incidents and then displayed dashboards that presented them with visualizations to categorize, correlate and identify opportunities. We then used the extracted words to identify business domain topics and find correlations between these domain topics using Latent Dirichlet Allocation (LDA). This statistical model provided a powerful framework for discovering and exploiting the hidden ‘topics’ or semantic themes in the data. Our tool made the framework scalable to collections of billions of documents with thousands of themes, which otherwise would have been impossible by human annotation.
                            </p>
                            <p>Some of the Images of the tool that can be shared in the public domain</p>
                            <Row className="image-container">
                                <Col>
                                    <img src={NIA_1} className="img-fluid"/>
                                    <img src={NIA_2} className="img-fluid"/>
                                    <img src={NIA_3} className="img-fluid"/>
                                    <img src={NIA_4} className="img-fluid"/>
                                </Col>
                            </Row>
                            <h3 className="text-muted mt-md-4">IMPACT</h3>
                            <p>Nia AIOps was deployed to more than 200 clients across Infosys. These are some of the impacts it had across various Industries and Functions. </p>
                            <Row>
                                <Col lg={6}>
                                    <h5>Banking and Financial services</h5>
                                    <ul>
                                        <li>Improvements in Mean Time To Recover (MTTR) <b>by 90% </b> in specific cases</li>
                                        <li><b>80%</b> reduction in SME dependency</li>
                                        <li><b>90%</b> IT Ops Effort reduction</li>
                                    </ul>
                                </Col>
                                <Col lg={6}>
                                    <h5>Communications</h5>
                                    <ul>
                                        <li><b>1 Mn USD</b> saved over 5 quarters </li>
                                        <li><b>21k+ </b> Man-hours saved over 2.5 years</li>
                                        <li><b>85%</b> reduction in manual activities across 7+ Line of Businesses </li>
                                    </ul>
                                </Col>

                                <Col lg={6}>
                                    <hr></hr>
                                    <h5>Electronics</h5>
                                    <ul>
                                        <li><b>1 Mn USD</b> per annum savings <b>88%</b> reduction in application outages</li>
                                        <li><b>98%</b> TAT improvement for repetitive tasks </li>
                                        <li><b>10%</b> monthly ticket volume automated </li>
                                        <li>Consistent SLA adherence of <b>99-100%</b> leading to customer delight  </li>
                                    </ul>
                                </Col>
                                <Col lg={6}>
                                    <hr></hr>
                                    <h5>Supply Chain & Logistics </h5>
                                    <ul>
                                        <li><b>43%</b> Tickets resolved automatically </li>
                                        <li><b>40% </b>Improvement in Productivity </li>
                                    </ul>
                                </Col>
                            </Row>
                            <h3 className="mt-md-4 text-muted">LEARNINGS</h3>
                            <p>Given the novelty of technology and the agile nature of the project, I was able to learn hands-on a variety of upcoming tools, frameworks and architectures. By building demos for clients across various industries I became an emphatic developer, prioritizing considerations for business and design decisions while still managing the engineering constraints . I learnt that Purposeful AI is about leveraging technology to amplify people. We can automate the repetitive, mechanizable tasks; we can capture the knowledge and know-how across people and long-lived systems and bring this knowledge back inside the systems to drive more value; and in doing these things we can free people to put all of our creativity, passion, and imagination into thinking about the bigger opportunities ahead of us. </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default Nia